<template>
    <div class="reviews-inner">
        <div class="reviews-content">
            <h2 class="header-title">Check <span>reviews</span></h2>
            <span class="description">{{ fn.tr('check review lorem ipsum') }}</span>
            <div class="reviews-items">
                <transition-group name="list-complete" tag="section">
                    <div v-for="(item,index) in activeReviews"
                         :key="index"
                         class="review-item list-complete-item">
                        <div class="item-text">
                            <div v-html="item.content"></div>
                            <div class="down-arrow"></div>
                        </div>
                        <div :style="{background:item.color}" class="name-container">
                            <span>{{ item.initial }}</span>
                        </div>
                        <span :style="{color:item.color}" class="name">{{ item.title }}</span>
                        <span class="date">{{ item.date }}</span>
                    </div>
                </transition-group>
            </div>
            <animated-button
                :class="{disable:disableButton}"
                @click.native="loadMore"
                :title="fn.tr('Load more')"/>
        </div>
    </div>
</template>

<script>
import AnimatedButton from '@/components/custom/AnimatedButton'
import {mapGetters} from "vuex";

export default {
    props: {
        content: {
            type: Object
        }
    },
    data() {
        return {
            number: 1,
            colors: ['#08B86F', '#F3AA00', '#D31F4C', '#34B1F5']
        }
    },
    components: {
        AnimatedButton
    },
    computed: {
        ...mapGetters({
            scroll: 'scroll/scroller'
        }),
        reviews() {
            return this.content?.data?.list?.map(item => ({
                ...item,
                color: this.colors[this.randomIntFromInterval(0, 3)],
                initial: item.title?.split('')?.[0]
            }))
        },
        activeReviews() {
            return this.reviews?.slice(0, this.number * 3) || [];
        },
        disableButton() {
            return this.activeReviews?.length === this.reviews?.length
        }
    },
    methods: {
        randomIntFromInterval(min, max) {
            return Math.floor(Math.random() * (max - min + 1) + min)
        },
        loadMore() {
            this.number++
            setTimeout(() => {
                this.scroll?.update()
            }, 300)
        }
    }
}
</script>

<style lang="scss" scoped>
.reviews-inner {
    min-height: 100vh;
    padding-top: 120px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: 1023px) {
        padding: 0 21px;
        margin-top: 90px;

    }

    ::v-deep .reviews-content {
        width: fit-content;
        display: flex;
        align-items: center;
        flex-direction: column;

        .header-title {
            font-weight: 900;
            font-size: 55px;
            text-align: center;
            display: flex;
            align-items: center;
            @media only screen and (max-width: 1023px) {
                font-size: 34px;
                margin-bottom: 10px;
            }

            span {
                color: $blue;
                margin-left: 10px;
            }
        }

        .description {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            margin-top: 5px;
            display: block;
        }

        .reviews-items {
            display: flex;
            margin: 40px auto 0;
            max-width: 1000px;
            @media only screen and (max-width: 1023px) {
                margin: 40px auto 0;
            }

            section {
                width: 100%;
                display: flex;
                align-items: flex-start;
                flex-wrap: wrap;
            }

            .review-item {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                margin-right: 30px;
                width: 31%;
                margin-top: 30px;
                @media only screen and (max-width: 1023px) {
                    width: calc(33.3% - 20px);
                }
                @media only screen and (max-width: 767px) {
                    width: 100%;
                    margin-right: 0 !important;
                    margin-top: 60px !important;
                }

                &:nth-child(2n) {
                    margin-top: 60px;
                }

                &:nth-child(3n) {
                    margin-right: 0;
                    margin-top: 20px;
                }

                .item-text {
                    position: relative;
                    padding: 35px 19px;
                    background: #FFFFFF;
                    border-radius: 10px;

                    p, span {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        text-align: center;
                        color: $black;
                    }

                    .down-arrow {
                        position: absolute;
                        bottom: -19px;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 0;
                        height: 0;
                        border-left: 15px solid transparent;
                        border-right: 15px solid transparent;
                        border-top: 20px solid white;
                    }
                }

                .name-container {
                    width: 80.56px;
                    height: 80.56px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 39px;

                    span {
                        font-weight: 900;
                        font-size: 55px;
                        color: #000000;
                        text-transform: uppercase;
                    }
                }

                .name {
                    margin-top: 13px;
                    font-weight: 700;
                    font-size: 21px;
                    line-height: 28px;
                }

                .date {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }

        .button {
            margin-top: 74px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 156px;
            height: 40px;
            background: $yellow;
            border-radius: 30px;
            font-weight: 700;
            font-size: 16px;
            text-transform: uppercase;
            color: $black;
            cursor: pointer;
            transition: all .45s ease;

            &.disable {
                background: black;
                color: white;
            }
        }
    }

}

.list-complete-item {
    transition: all 1s;
    display: inline-block;
    margin-right: 10px;
}

.list-complete-enter, .list-complete-leave-to
    /* .list-complete-leave-active below version 2.1.8 */
{
    opacity: 0;
    transform: translateY(30px);
}

.list-complete-leave-active {
    position: absolute;
}
</style>
