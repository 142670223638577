<template>
    <animated-button
        @click.native="openForm"
        :title="fn.tr('Log in')"
        class="log-in button"/>
</template>

<script>
import AnimatedButton from '@/components/custom/AnimatedButton'

export default {
    components: {
        AnimatedButton
    },
    methods: {
        openForm() {
            this.$root.$emit('fire_pop_up', {id: 'order/LogInPopUp'})
        }
    }
}
</script>

<style lang="scss" scoped>
.button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 114px;
    height: 40px;
    border-radius: 30px;
    font-weight: 700;
    font-size: 16px;
    line-height: 34px;
    text-transform: uppercase;
    cursor: pointer;
    background: $yellow;
    color: $black;
    border: 1px solid white;
    width: fit-content;
}
</style>
