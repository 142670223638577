<template>
    <div class="home-contact">
        <a :href="'mailto:'+content.title"
           class="call-button">
            <animated-button
                :title=" content.mail"/>
        </a>
        <div class="text-content">
        <span data-scroll
              :data-scroll-speed="1"
              class="text">
                           {{ content.adress }}
        </span>
            <span data-scroll
                  :data-scroll-speed="1.5"
                  class="text">   {{ content.day }}</span>
        </div>

    </div>
</template>

<script>
import {mapGetters} from "vuex";
import AnimatedButton from '@/components/custom/AnimatedButton'

export default {
    components: {
        AnimatedButton
    },
    computed: {
        ...mapGetters({
            indx: 'menu/indx'
        }),
        content() {
            return this.indx?.widgets?.call || {}
        }
    }
}
</script>

<style lang="scss" scoped>
.home-contact:deep() {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @media only screen and (max-width: 1023px) {
        height: auto;
        padding: 100px 0;
    }

    .call-button {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        font-weight: 500;
        font-size: 34px;
        color: $black;
        background: $green;
        text-decoration: none;
        font-weight: bold;
        @media only screen and (max-width: 1023px) {
            font-size: 21px;
            padding: 20px 30px;
        }

        p {
            margin-bottom: 0;
        }

        span {
            color: white;
        }

        .button {
            padding: 24px 49px;
            background: transparent;

            span {
                line-height: unset !important;
            }

            @media only screen and (max-width: 1023px) {
                padding: 0;
            }
        }
    }

    .text-content {
        margin-top: 55px;
        width: 252px;

        .text {
            color: white;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            display: block;

            &:last-child {
                margin-top: 25px;
            }
        }
    }
}
</style>
