<template>
    <div class="typewriter">
        <div class="typed-text" :id="textId"></div>
    </div>
</template>

<script>
export default {
    props: {
        text: {
            type: Array,
            default: () => []
        },
        id: {
            type: Number,
            default: 1
        },
        startTyping: {
            type: Boolean,
        },
    },
    computed: {
        textId() {
            return `typedText${this.id}`
        }
    },
    methods: {
        writeText() {
            // set up text to print, each item in array is new line
            let aText = this.text
            let iSpeed = 50; // time delay of print out
            let iIndex = 0; // start printing array at this posision
            let iArrLength = aText[0].length; // the length of the text array
            let iScrollAt = 20; // start scrolling up at this many lines

            let iTextPos = 0; // initialise text position
            let sContents = ''; // initialise contents variable
            let iRow; // initialise current row

            const typewriter = () => {
                sContents = ' ';
                iRow = Math.max(0, iIndex - iScrollAt);
                let destination = document.getElementById(this.textId);

                while (iRow < iIndex) {
                    sContents += aText[iRow++] + '<br />';
                }
                if (destination) destination.innerHTML = sContents + aText[iIndex].substring(0, iTextPos);
                if (iTextPos++ === iArrLength) {
                    iTextPos = 0;
                    iIndex++;
                    if (iIndex !== aText.length) {
                        iArrLength = aText[iIndex].length;
                        setTimeout(() => {
                            typewriter()
                        }, 500);
                    }
                } else {
                    setTimeout(() => {
                        typewriter()
                    }, iSpeed);
                }
            }

            typewriter();
        }
    },
    watch: {
        startTyping(val) {
            if (val) {
                setTimeout(() => {
                    this.writeText()
                }, 600)
            }
        }
    },
    mounted() {
        //needed update
        setTimeout(() => {
            this.writeText()
        }, 2500)
    },
    beforeDestroy() {
    }
}
</script>

<style lang="scss" scoped>
.typed-text {
    color: white;
    font-style: normal;
    font-weight: 900;
    font-size: 55px;

    span {
        color: $blue;
    }

    @media only screen and (max-width: 1650px) and (min-width: 1024px) {
        font-size: 28px;
    }
    @media only screen and (max-width: 1023px) {
        font-size: 21px;
    }
}

</style>
