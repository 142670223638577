<template>
    <div class="loader">
        <div class="loader-nav">
            <div class="line"></div>
            <div :style="{transform:`translateY(calc(50% + ${percentage}px))`}" class="percentage">
                {{ Math.floor(percentage) }}%
            </div>
        </div>
        <svg class="image" width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                id="red"
                d="M17.2496 59.1222C21.8737 59.1222 25.6222 55.3737 25.6222 50.7496C25.6222 46.1255 21.8737 42.377 17.2496 42.377C12.6255 42.377 8.87695 46.1255 8.87695 50.7496C8.87695 55.3737 12.6255 59.1222 17.2496 59.1222Z"
                fill="#D31F4C"/>
            <path
                id="blue"
                d="M50.7496 25.6222C55.3737 25.6222 59.1222 21.8737 59.1222 17.2496C59.1222 12.6255 55.3737 8.87695 50.7496 8.87695C46.1255 8.87695 42.377 12.6255 42.377 17.2496C42.377 21.8737 46.1255 25.6222 50.7496 25.6222Z"
                fill="#34B1F5"/>
            <path
                id="green"
                d="M50.7499 32.9551C50.5711 32.9551 50.3922 32.9645 50.2134 32.9692C45.8835 33.0492 38.0144 33.9999 33.9999 33.9999C33.9999 38.0474 33.0304 46.0153 32.9645 50.3216C32.9645 50.4628 32.9551 50.6087 32.9551 50.7499C32.9551 60.2897 40.6877 67.4999 50.2275 67.4999C59.7673 67.4999 67.4999 59.7673 67.4999 50.2275C67.4999 40.6877 60.2897 32.9551 50.7499 32.9551ZM50.7499 59.1272C46.1235 59.1272 42.3772 55.3763 42.3772 50.7546C42.3772 46.1329 46.1282 42.3772 50.7499 42.3772C55.3716 42.3772 59.1225 46.1282 59.1225 50.7546C59.1225 55.381 55.3716 59.1272 50.7499 59.1272Z"
                fill="#08B86F"/>
            <path
                id="yellow"
                d="M17.25 35.0448C17.4288 35.0448 17.6077 35.0354 17.7865 35.0307C22.1164 34.9507 29.9855 34 34 34C34 29.9525 34.9695 21.9846 35.0354 17.6783C35.0354 17.5371 35.0448 17.3912 35.0448 17.25C35.0448 7.71017 27.3122 0.5 17.7724 0.5C8.23258 0.5 0.5 8.23258 0.5 17.7724C0.5 27.3122 7.71017 35.0448 17.25 35.0448ZM17.25 8.87265C21.8764 8.87265 25.6226 12.6236 25.6226 17.2453C25.6226 21.867 21.8717 25.6226 17.25 25.6226C12.6283 25.6226 8.87735 21.8717 8.87735 17.2453C8.87735 12.6189 12.6283 8.87265 17.25 8.87265Z"
                fill="#F3AA00"/>
        </svg>
    </div>
</template>

<script>
// import BezierEasing from 'bezier-easing'
import {gsap} from "gsap";
import {mapActions} from "vuex";

export default {
    data() {
        return {
            percentage: 0,
            fillInterval: null
        }
    },
    methods: {
        ...mapActions({
            setLoader: 'menu/setLoader'
        }),
        fillLine() {
            gsap.fromTo(".loader-nav .line",
                {height: 0},
                {
                    height: innerHeight,
                    duration: 2,
                    delay: .4,
                    onUpdate: () => {
                        this.percentage += 0.82
                    },
                    onComplete: () => {
                        // this.setLoader(false)
                    }
                });
            gsap.fromTo(".loader-nav",
                {opacity: 0},
                {
                    opacity: 1,
                    delay: .2,
                    duration: 1,
                });
        }
    },
    created() {
        this.$nextTick(() => {
            this.fillLine()
        })
    }
}
</script>

<style lang="scss" scoped>
$ease: cubic-bezier(.65, -0.01, .26, .99);
.loader {
    background: $black;
    z-index: 102;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;

    .loader-nav {
        display: flex;
        align-items: flex-end;

        .line {
            height: 0;
            width: 4px;
            background: $red;
        }

        .percentage {
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            color: $red;
            margin-left: 30px;
            margin-bottom: 15px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .image {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        animation: rotate 2.5s $ease infinite;

        path {
            transform-origin: center;
            transform: scale(0);
        }

        #red {
            animation: scale 2.5s $ease infinite;
        }

        #yellow {
            animation: scale 2.5s .3s $ease infinite;
        }

        #green {
            animation: scale 2.5s .6s $ease infinite;
        }

        #blue {
            animation: scale 2.5s .2s $ease infinite;
        }
    }
}

@keyframes rotate {
    100% {
        transform: translate(-50%, -50%) rotate(180deg);
    }
}

@keyframes scale {
    0%, 100% {
        transform: scale(0);
    }
    50% {
        transform: scale(1);
    }
}
</style>
