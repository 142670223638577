function apiUrlGenerator(view = true) {
    let lastPath = view ? '/source/api/view/' : '/source/api/member/'
    const url = window.location.origin + lastPath;

    if (process.env.NODE_ENV === 'production') return url;
    const samples = {
        apiUrl: "https://tagandmatch.palindroma.ge" + lastPath,
    }
    return samples.apiUrl;
}

export default {
    apiUrl: apiUrlGenerator(),
    adminUrl: apiUrlGenerator(false),
    websiteUrl: window.location.origin,
};
