<template>
    <div :class="{hidden:activeStepId>1}" class="home">
        <div :class="{active:activeStepId===0,hidden:activeStepId>1,start:loader}" class="street-image-inner">
            <img src="../assets/images/street.png"
                 rel="preload"
                 alt="street">
            <PinComponent v-for="(item,index) in pines"
                          :color="item.color" :key="index"></PinComponent>
        </div>
        <div class="upper-content">
            <div :class="{active:activeStepId===0,hidden:activeStepId>1,start:loader}" class="first-content-inner">
                <div class="mobile-header-title" v-html="teaser"></div>
                <div class="first-content">
                    <div class="header-title" v-html="teaser">
                    </div>

                    <div class="hidden-inner">
                        <h2 class="hidden-header-title">{{ firstBox.title }}</h2>
                        <div class="title" v-html="firstBox.teaser"></div>
                    </div>

                </div>
                <div :style="{background:firstCard.colorBg}" class="left-box">
                    <div class="up">
                        <span class="title">{{ firstCard.slug }}</span>
                        <img src="../assets/images/triangle.svg" alt="triangle">
                    </div>
                    <svg width="158" height="77"
                         class="vector-image"
                         viewBox="0 0 158 77" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3 73.5894C108.139 73.5894 66.8978 2.57959 155.272 2.57959" stroke="#D31F4C"
                              stroke-width="5" stroke-linecap="round"/>
                    </svg>

                    <div class="about">
                        <span class="title">{{ firstCard.title }} </span>
                        <div class="about-nav">
                            <span class="title">{{ firstCard.teaser }} </span>
<!--                            <img src="../assets/images/info.svg" alt="info">-->
                        </div>
                    </div>
                    <animated-button
                        @click.native="showDiscoverPopUp"
                        :title="firstCard.hover_title "/>
                    <!--                                        <div @click="showDiscoverPopUp"-->
                    <!--                                             class="button">{{ }}-->
                    <!--                                        </div>-->
                </div>
                <div class="right-box">
                    <h2 class="box-header-title">{{ secondCard.title }}</h2>
                    <div class="title" v-html="secondCard.teaser"></div>
                    <router-link to="/calculator">
                        <animated-button :title="secondCard.hover_title  "/>
                    </router-link>
                </div>
            </div>
            <div :class="{active:activeStepId===1,hidden:activeStepId>1}" class="second-content">
                <div class="first-mobile-box box">
                    <h2 class="header-title">{{ firstBox.title }}</h2>
                    <div class="title" v-html="firstBox.teaser"></div>
                </div>

                <div v-for="(item,index) in boxes" :key="index" class="box">
                    <h2 class="header-title">{{ item.title }}</h2>
                    <div class="title" v-html="item.teaser"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PinComponent from "@/components/PinComponent";
import {mapGetters} from "vuex";
import Typewriter from "@/components/Typewriter";
import AnimatedButton from '@/components/custom/AnimatedButton'

export default {
    props: {
        activeStepId: {
            type: Number,
            default: 0
        },
        content: {
            type: Object
        }
    },
    data() {
        return {
            pines: [
                {
                    color: '#08B86F'
                },
                {
                    color: '#D31F4C'
                },
                {
                    color: '#08B86F'
                },
                {
                    color: '#F3AA00'
                },
                {
                    color: '#08B86F'
                },
                {
                    color: '#D31F4C'
                },
                {
                    color: '#34B1F5'
                },
                {
                    color: '#F3AA00'
                },
                {
                    color: '#08B86F'
                },
            ]
        }
    },
    components: {
        PinComponent,
        Typewriter,
        AnimatedButton
    },
    computed: {
        ...mapGetters({
            loader: 'menu/loader',
            indx: 'menu/indx',
        }),
        teaser() {
            return this.content?.home?.data?.list?.[0]?.teaser
        },
        firstCard() {
            return this.content?.home?.data?.list?.[0]?.cards?.[0] || {}
        },
        secondCard() {
            return this.content?.home?.data?.list?.[0]?.cards?.[1] || {}
        },
        widgetTexts() {
            return this.indx?.widgets?.texts
        },
        boxes() {
            return [
                {
                    title: this.widgetTexts?.title2,
                    teaser: this.widgetTexts?.teaser2,
                },
                {
                    title: this.widgetTexts?.title3,
                    teaser: this.widgetTexts?.teaser3,
                },
                {
                    title: this.widgetTexts?.title4,
                    teaser: this.widgetTexts?.teaser4,
                }
            ]
        },
        firstBox() {
            return {
                title: this.widgetTexts?.title1,
                teaser: this.widgetTexts?.teaser1,
            }
        }
    },
    methods: {
        showDiscoverPopUp() {
            this.$root.$emit('fire_pop_up', {id: 'DiscoverPopUp', data: this.content?.home?.data?.list?.[0]})
        }
    },
}
</script>

<style lang="scss" scoped>
$ease: cubic-bezier(.09, 0, .27, 1);
.home {
    pointer-events: auto;

    &.hidden {
        pointer-events: none;
    }
}

.upper-content {
    z-index: 1;
    position: relative;
    width: 100%;
    height: 100vh;
}

::v-deep.first-content-inner {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 100vh;
    bottom: 0;
    @media only screen and (max-width: 767px) {
        width: 100%;
    }

    &.start {
        .right-box, .left-box {
            //transform: translateY(10vh) !important;
        }
    }


    .right-box {
        position: absolute;
        background: $white;
        border-radius: 10px;
        bottom: 24vh;
        right: 0;
        padding: 27px;
        transform: translate(150%, 0);
        opacity: 0;
        width: 371px;
        transition: all .6s $ease;
        @media only screen and (max-width: 1650px) and (min-width: 1440px) {
            padding: 20px;
            width: 300px;
        }
        @media only screen and (max-width: 1439px) and (min-width: 768px) {
            padding: 15px;
            width: 300px;
        }
        @media only screen and (max-width: 1023px) and (min-width: 768px) {
            width: 300px;
        }
        @media only screen and (max-width: 767px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            max-width: 150px;
            bottom: 15vh;
        }

        .box-header-title {
            font-weight: 500;
            font-size: 34px;
            color: $black;
            @media only screen and (max-width: 1650px) and (min-width: 768px) {
                font-size: 28px;
            }
            @media only screen and (max-width: 767px) {
                font-size: 21px;
            }

        }

        .title {
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: $black;
            @media only screen and (max-width: 767px) {
                display: none;
            }

            span {
                color: $red
            }
        }

        a {
            text-decoration: none;
        }

        .button {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 109px;
            height: 30px;
            background: $red;
            border-radius: 30px;
            font-weight: 700;
            font-size: 13px;
            line-height: 34px;
            text-transform: uppercase;
            color: $white;
            margin-top: 17.5px;
            text-decoration: none;
            transition: all .45s ease;

        }
    }

    .left-box {
        position: absolute;
        background: $yellow;
        border-radius: 10px;
        bottom: 3vh;
        width: 184px;
        left: 0;
        transform: translateX(-200%);
        opacity: 0;
        padding: 15px;
        transition: all .6s $ease;
        @media only screen and (max-width: 1439px) and (min-width: 768px) {
            padding: 15px;
        }
        @media only screen and (max-width: 767px) {
            bottom: 20vh;
            width: 166px;
        }

        .title {
            color: $black
        }

        .up {
            display: flex;
            align-items: center;

            .title {
                font-size: 34px;
                font-weight: 500;
            }

            img {
                margin-left: 20px;
            }
        }

        .vector-image {
            width: 100%;
            stroke-dasharray: 1000;
            stroke-dashoffset: 1000;
            animation: dash 5s 2.7s linear forwards;
            @media only screen and (max-width: 1023px) {
                display: none;
            }
        }

        @keyframes dash {
            to {
                stroke-dashoffset: 0;
            }
        }

        .about {
            margin-top: 12px;

            .title {
                font-weight: 700;
                font-size: 16px;
            }

            .about-nav {
                display: flex;
                align-items: center;

                img {
                    margin-left: 5px;
                }
            }
        }

        .button {
            margin-top: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 108px;
            height: 30px;
            background: $white;
            border-radius: 30px;
            font-weight: 700;
            font-size: 13px;
            line-height: 34px;
            text-transform: uppercase;
            color: $black;
            cursor: pointer;
        }
    }

    &.active {
        .first-content {
            height: 75vh;
            border: 2px solid #FFFFFF;
            transition: all .6s .4s $ease;
            transform: translateY(30vh);
            @media only screen and (max-width: 1650px) and (min-width: 1024px) {
                transform: translateY(25vh);
                height: 80vh;
            }
        }


        .mobile-header-title {
            opacity: 1;
            transform: translate(-50%, 0);
            transition: all .6s .75s $ease;
        }

        .right-box {
            transform: translate(50%, 0);
            opacity: 1;
            transition: all .6s .75s $ease;
            @media only screen and (max-width: 767px) {
                transform: translateX(-20px);
            }
        }

        .left-box {
            transform: translateX(-50%);
            opacity: 1;
            transition: all .6s .75s $ease;
            @media only screen and (max-width: 767px) {
                transform: translateX(20px);
            }
        }

        .hidden-inner {
            opacity: 0;
            transition: all .6s $ease !important;
        }

        .header-title {
            opacity: 1 !important;
            transition: all .6s .75s $ease !important;
        }
    }

    &.hidden {
        .first-content {
            transform: translateY(100vh);
            transition-delay: .4s;
        }
    }

    .mobile-header-title {
        display: none;
        font-style: normal;
        font-weight: 900;
        font-size: 34px;
        text-align: center;
        color: #FFFFFF;
        position: absolute;
        top: 20%;
        left: 50%;
        opacity: 0;
        transform: translate(-50%, 30px);
        transition: all .6s $ease;
        pointer-events: none;
        @media only screen and (max-width: 767px) {
            display: block;
        }

        p {
            display: block;
            margin-bottom: 0 !important;

            &:first-child {
                color: $green;
            }

            &:nth-child(2) {
                animation-delay: 1s;
            }

            &:last-child {
                color: $blue;
                animation-delay: 1.8s;
            }
        }

    }

    .first-content {
        position: relative;
        transform: translateY(20vh);
        border: 2px solid $green;
        border-radius: 36px;
        height: 140px;
        width: 580px;
        transition: all .6s $ease;
        @media only screen and (max-width: 1650px) and (min-width: 768px) {
            height: 99.5px;
            width: 450px;
        }
        @media only screen and (max-width: 1023px) {
            transform: translateY(12vh);
            width: 400px;
        }
        @media only screen and (max-width: 767px) {
            display: none;
        }

        .header-title {
            padding: 40px 40px 0;
            font-style: normal;
            font-weight: 900;
            font-size: 55px;
            color: $white;
            line-height: 67px;
            opacity: 0;
            transition: all .6s $ease;
            @media only screen and (max-width: 1280px) and (min-width: 1024px) {
                font-size: 34px;
                line-height: 51px;
            }
            @media only screen and (max-width: 1650px) and (min-width: 1024px) {
                padding: 25px 25px 0;
            }
            @media only screen and (max-width: 1023px) {
                font-size: 34px;
                line-height: 51px;
                padding: 25px 25px 0;
            }

            p {
                display: block;
                margin-bottom: 0;
                width: 6.4em;
                animation: type 1s steps(40, end);
                animation-delay: .1s;
                -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards;
                white-space: nowrap;
                overflow: hidden;
                opacity: 0;

                &:first-child {
                    color: $green;
                }

                &:nth-child(2) {
                    animation-delay: 1s;
                }

                &:last-child {
                    color: $blue;
                    animation-delay: 1.9s;
                }
            }

        }

        @keyframes type {
            0% {
                width: 0;
                opacity: 0;
            }
            99.9% {
                opacity: 1;
            }
            100% {
                opacity: 1;
                border: none;
            }
        }

        .hidden-inner {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            color: white;
            transition: all .6s .45s $ease;
            width: 100%;
            padding: 21px 30px;
            @media only screen and (max-width: 1650px) and (min-width: 768px) {
                padding: 15px 30px;
            }

            .hidden-header-title {
                font-style: normal;
                font-weight: 900;
                font-size: 55px;
                margin-bottom: 0;
                @media only screen and (max-width: 1650px) and (min-width: 1024px) {
                    font-size: 34px;
                }
                @media only screen and (max-width: 1023px) {
                    font-size: 21px;
                }
            }

            .title {
                font-weight: 400;
                font-size: 21px;
                line-height: 25px;

                p {
                    margin-bottom: 0;
                }

                @media only screen and (max-width: 1650px) and (min-width: 768px) {
                    font-size: 18px;
                }
            }
        }
    }

}

.second-content {
    position: absolute;
    left: 50%;
    width: 75%;
    top: calc(20vh + 190px);
    display: flex;
    flex-direction: column;
    transform: translate(-50%, 0);
    pointer-events: none;
    @media only screen and (max-width: 1650px) and (min-width: 1024px) {
        top: calc(20vh + 143px);
    }
    @media only screen and (max-width: 1023px) {
        top: calc(12vh + 143px);
    }
    @media only screen and (max-width: 767px) {
        width: calc(100% - 42px);
        top: 120px;
    }

    &.active {
        pointer-events: auto;

        .box {
            transform: translateY(0) !important;


            &:first-child {
                transition-delay: .2s;
            }

            &:nth-child(2) {
                transition-delay: .3s;
            }

            &:last-child {
                transition-delay: .4s;
            }
        }
    }

    ::v-deep .box {
        position: relative;
        padding: 21px 30px;
        border: 2px solid;
        border-radius: 36px;
        color: white;
        width: fit-content;
        margin-top: 50px;
        transition: transform .7s $ease;
        @media only screen and (max-width: 1650px) and (min-width: 768px) {
            padding: 15px 30px;
            margin-top: 50px;
        }
        @media only screen and (max-width: 767px) {
            padding: 15px;
            border-radius: 21px;
        }

        &.first-mobile-box {
            display: none;
            margin-top: 0;
            border-color: $green;
            transform: translateY(100vh);
            transition-delay: .4s;
            @media only screen and (max-width: 767px) {
                display: block;
            }

        }

        &:nth-child(2) {
            margin-top: 0;
            border-color: $yellow;
            align-self: flex-end;
            transition-delay: .3s;
            transform: translateY(100vh);
            @media only screen and (max-width: 767px) {
                margin-top: 50px;
            }
        }

        &:nth-child(3) {
            border-color: $blue;
            align-self: flex-start;
            transition-delay: .2s;
            transform: translateY(100vh);
        }

        &:last-child {
            border-color: $red;
            align-self: center;
            transition-delay: 0s;
            transform: translateY(100vh);

        }

        .header-title {
            font-style: normal;
            font-weight: 900;
            font-size: 55px;
            @media only screen and (max-width: 1650px) and (min-width: 1024px) {
                font-size: 34px;
            }
            @media only screen and (max-width: 1023px) {
                font-size: 21px;
                margin-bottom: 0;
            }
        }

        .title {
            font-weight: 400;
            font-size: 21px;
            line-height: 25px;

            @media only screen and (max-width: 1650px) and (min-width: 768px) {
                font-size: 18px;
            }
            @media only screen and (max-width: 767px) {
                font-size: 13px;
            }

            p {
                margin-bottom: 0;
            }
        }
    }
}

.street-image-inner {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;

    &.active, &.hidden {
        img {
            transform: scale(1);
        }

        .pin {
            transform: scale(.8) translateY(25px);
            opacity: 0;
            @for $i from 1 through 10 {
                &:nth-child(#{$i}) {
                    transition-delay: $i/15+s;
                }
            }
        }
    }

    &.hidden {
        img {
            opacity: 0;
        }
    }

    &.start {
        img {
            transform: scale(.8) rotate(10deg);
        }
    }

    img {
        transition: all 1s $ease;
        transform: scale(1.8) rotate(0);
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .pin {
        transform: scale(1) translateY(0);
        opacity: 1;
        transition: opacity .6s $ease, transform .6s $ease;
        @for $i from 1 through 11 {
            &:nth-child(#{$i}) {
                transition-delay: .3+$i/10+s;
            }
        }
    }
}
</style>
