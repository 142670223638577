import fn from '../../helpers/func'
import router from '../../router/index'

export default {
    namespaced: true, state: () => ({
        _indx: null, _translations: null, _current_content: {}, _loader: true
    }), getters: {
        indx(state) {
            return state._indx
        }, translations(state) {
            return state._translations
        }, current_content(state) {
            return state._current_content
        }, loader(state) {
            return state._loader
        }
    }, actions: {
        async fetchIndx({commit}) {
            commit('SET_LOADER', true)
            let {data} = await fn.postData("main/indx");
            let websiteTitle = data?.siteSettings?.website_title
            if (websiteTitle) document.title = websiteTitle
            commit('SET_INDX', data)
        }, async getTranslations({commit}) {
            let tmp = await fn.postData("main/getTranslations");
            commit('SET_TRANSLATIONS', tmp.data)
        }, async getCurrentContent({commit, state}, route) {
            commit('SET_LOADER', true)
            let menus = state._indx?.menus
            let path = route?.path || router.currentRoute?.path
            if (path[0] === '/') path = path.substring(1)

            let currentMenu = menus?.find(item => item.url_slug === path)

            let {data} = await fn.postData("main/getCurrentContent", {
                contentid: currentMenu?.id,
            });
            setTimeout(() => {
                commit('SET_LOADER', false)
            },1000)

            commit('SET_CURRENT_CONTENT', data.secondary)
        }, setLoader({commit}, value) {
            commit('SET_LOADER', value)
        }
    }, mutations: {
        SET_INDX(state, data) {
            state._indx = data
        }, SET_TRANSLATIONS(state, data) {
            state._translations = data
        }, SET_CURRENT_CONTENT(state, data) {
            state._current_content = data
        }, SET_LOADER(state, value) {
            state._loader = value
        },
    },
}
