var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-slider",class:{active:_vm.activeStepId===3,expand:_vm.activeStepId===4}},[_c('div',{staticClass:"box-container",style:({borderColor:_vm.activeSlideContent.color})},_vm._l((_vm.slides),function(item,index){return _c('div',{key:index,staticClass:"content",class:{active:index===_vm.activeSlideId}},[_c('typewriter',{attrs:{"startTyping":index===_vm.activeSlideId,"id":index,"text":[item.text]}}),_c('div',{staticClass:"item"},[_c('animated-button',{style:({background:item.color}),attrs:{"title":item.button},nativeOn:{"click":function($event){return _vm.showPopUp(item,$event)},"touchend":function($event){return _vm.showPopUp(item,$event)}}})],1)],1)}),0),_c('div',{staticClass:"swiper-container home-slider-wrapper-container",class:{'first-transition':_vm.firstSlideTransition}},[_c('div',{staticClass:"swiper-wrapper"},[_c('div',{staticClass:"swiper-slide",on:{"click":function($event){return _vm.sliderEvent(0)}}},[_vm._m(0),_vm._m(1),_c('img',{staticClass:"arrow",attrs:{"src":require("../assets/images/arrow.png"),"alt":"arrow"}})]),_c('div',{staticClass:"swiper-slide",on:{"click":function($event){return _vm.sliderEvent(1)}}},[_vm._m(2),_vm._m(3),_c('img',{staticClass:"arrow",attrs:{"src":require("../assets/images/arrow.png"),"alt":"arrow"}})]),_c('div',{staticClass:"swiper-slide",on:{"click":function($event){return _vm.sliderEvent(2)}}},[_vm._m(4),_vm._m(5),_c('img',{staticClass:"arrow",attrs:{"src":require("../assets/images/arrow.png"),"alt":"arrow"}})])])]),_c('walking-paragraph',{attrs:{"expand":_vm.activeStepId===4}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"slide-content"},[_c('div',{staticClass:"pin"},[_c('img',{staticClass:"pin-image",attrs:{"src":require("../assets/images/pin.png"),"alt":"pin"}}),_c('img',{staticClass:"circle",attrs:{"src":require("../assets/images/ellipse.png"),"alt":"circle"}}),_c('img',{staticClass:"circle",attrs:{"src":require("../assets/images/circle.png"),"alt":"circle"}}),_c('img',{staticClass:"circle",attrs:{"src":require("../assets/images/fadeCircle.png"),"alt":"circle"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mark"},[_c('img',{staticClass:"mark-image",attrs:{"src":require("../assets/images/mark.svg"),"alt":"mark"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"slide-content plans"},[_c('img',{staticClass:"plan-img",attrs:{"src":require("../assets/images/plan.png"),"alt":"plan"}}),_c('img',{staticClass:"plan-img",attrs:{"src":require("../assets/images/redPlan.png"),"alt":"plan"}}),_c('img',{staticClass:"plan-img",attrs:{"src":require("../assets/images/plan.png"),"alt":"plan"}}),_c('img',{staticClass:"cursor",attrs:{"src":require("../assets/images/cursor.png"),"alt":"cursor"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mark"},[_c('img',{staticClass:"mark-image",attrs:{"src":require("../assets/images/mark.svg"),"alt":"mark"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"slide-content"},[_c('img',{staticClass:"envelope-image",attrs:{"src":require("../assets/images/envelope.png"),"alt":"envelope"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mark"},[_c('img',{staticClass:"mark-image",attrs:{"src":require("../assets/images/mark.svg"),"alt":"mark"}})])
}]

export { render, staticRenderFns }