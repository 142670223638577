let canvas = ''
let ctx = null
let balls = []
let ballsNumber = 10
let colors = ['#F3AA00', '#D31F4C', '#34B1F5', '#08B86F']

function documentReady(init) {
    document.addEventListener("DOMContentLoaded", function (event) {
        init()
    });
}

function init() {
    canvas = document.getElementById('canvas')
    setCanvasParameters();
    balls = CreateBalls()
    animateBalls()
}

function destroy() {
    canvas = ''
    ctx = null
    balls = []
}

function setCanvasParameters() {
    canvas.width = window.innerWidth + 200
    canvas.height = window.innerHeight + 200
    ctx = canvas.getContext('2d')
}

function Circle(x, y, r, c, i) {
    this.x = x;
    this.y = y;
    this.r = r;
    this.c = c;
    this.id = i
    this.dx = (Math.random() * 4) + 1;
    this.dx *= this.dx < 2 ? -1 : 1
    this.dy = (Math.random() * 4) + 1;
    this.dy *= this.dy < 2 ? -1 : 1
    this.timeOut = null
    this.draw = () => {
        ctx.beginPath()
        ctx.fillStyle = this.c;
        ctx.ellipse(this.x, this.y, this.r, this.r, 0, 0, Math.PI * 2);
        ctx.fill()
    }

    this.animate = () => {
        this.x += this.dx;
        this.y += this.dy;
        if (this.x + this.r > canvas.width || this.x - this.r < 0) {
            this.dx = -this.dx
        }
        if (this.y + this.r > canvas.height || this.y - this.r < 0) {
            this.dy = -this.dy
        }
        this.draw()
    }
}

function CreateBalls() {
    let balls = []
    for (let i = 0; i < ballsNumber; i++) {
        let r = Math.floor(Math.random() * 5) + 3
        let x = Math.random() * (canvas.width - 2 * r) + r
        let y = Math.random() * (canvas.height - 2 * r) + r
        let c = colors[Math.floor(i % 4)];
        balls.push(new Circle(x, y, r, c, i))
    }
    return balls
}

function clearCanvas() {
    ctx?.clearRect(0, 0, canvas.width, canvas.height)
}

function animateBalls() {
    clearCanvas()
    balls.forEach(item => {
        item.animate()
    })
    requestAnimationFrame(animateBalls)
}

//run functions

export {init, destroy}
