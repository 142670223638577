<template>
    <div :class="{active:activeStepId===3,expand:activeStepId===4}" class="home-slider">
        <div :style="{borderColor:activeSlideContent.color}" class="box-container">
            <div v-for="(item,index) in slides"
                 :key="index"
                 :class="{active:index===activeSlideId}"
                 class="content">
                <typewriter :startTyping="index===activeSlideId"
                            :id="index"
                            :text="[item.text]"/>
                <div class="item">
                    <animated-button
                        @click.native="showPopUp(item,$event)"
                        @touchend.native="showPopUp(item,$event)"
                        :style="{background:item.color}"
                        :title="item.button "/>
                </div>
            </div>
        </div>
        <div :class="{'first-transition':firstSlideTransition}"
             class="swiper-container home-slider-wrapper-container">
            <div class="swiper-wrapper">
                <div @click="sliderEvent(0)" class="swiper-slide">
                    <div class="slide-content">
                        <div class="pin">
                            <img src="../assets/images/pin.png" class="pin-image" alt="pin">
                            <img src="../assets/images/ellipse.png" class="circle" alt="circle">
                            <img src="../assets/images/circle.png" class="circle" alt="circle">
                            <img src="../assets/images/fadeCircle.png" class="circle" alt="circle">
                        </div>
                    </div>
                    <div class="mark">
                        <img src="../assets/images/mark.svg" class="mark-image" alt="mark">
                    </div>
                    <img src="../assets/images/arrow.png" class="arrow" alt="arrow">
                </div>
                <div @click="sliderEvent(1)" class="swiper-slide">
                    <div class="slide-content plans">
                        <img src="../assets/images/plan.png" class="plan-img" alt="plan">
                        <img src="../assets/images/redPlan.png" class="plan-img" alt="plan">
                        <img src="../assets/images/plan.png" class="plan-img" alt="plan">
                        <img src="../assets/images/cursor.png" class="cursor" alt="cursor">
                    </div>
                    <div class="mark">
                        <img src="../assets/images/mark.svg" class="mark-image" alt="mark">
                    </div>
                    <img src="../assets/images/arrow.png" class="arrow" alt="arrow">
                </div>
                <div @click="sliderEvent(2)" class="swiper-slide">
                    <div class="slide-content">
                        <img src="../assets/images/envelope.png" class="envelope-image" alt="envelope">
                    </div>
                    <div class="mark">
                        <img src="../assets/images/mark.svg" class="mark-image" alt="mark">
                    </div>
                    <img src="../assets/images/arrow.png" class="arrow" alt="arrow">
                </div>
            </div>
        </div>
        <walking-paragraph :expand="activeStepId===4"/>
    </div>
</template>

<script>
import 'swiper/css';
import Swiper, {Mousewheel, EffectCoverflow} from 'swiper';
import WalkingParagraph from "@/components/WalkingParagraph";
import Typewriter from "@/components/Typewriter";
import restrictNumber from "@/utils/helpers";
import AnimatedButton from '@/components/custom/AnimatedButton'

Swiper.use([Mousewheel, EffectCoverflow]);
export default {
    props: {
        activeStepId: {
            type: Number,
            default: 0
        },
        dataContent: {
            type: Array,
        }
    },
    data() {
        return {
            activeSlideId: 0,
            swiper: null,
            stop: false,
            mobileTouchStartY: 0,
            mobileTouchStartX: 0,
            firstSlideTransition: false
        }
    },
    components: {
        WalkingParagraph,
        Typewriter,
        AnimatedButton
    },
    computed: {
        activeSlideContent() {
            return this.slides?.[this.activeSlideId]
        },
        slides() {
            return this.dataContent?.map((item, index) => {
                let data = item?.data?.list?.[0] || {}

                return {
                    ...data,
                    text: data.title,
                    button: data.link?.title,
                    id: index === 0 ? 'TargetPopUp' : index === 1 ? 'PlanPopUp' : 'DeliveryPopUp',
                    color: index === 0 ? '#34B1F5' : index === 1 ? '#F3AA00' : '#08B86F',
                }
            })
        }
    },
    methods: {
        initSwiper() {
            this.swiper = new Swiper(".home-slider-wrapper-container", {
                slidesPerView: '1.2',
                grabCursor: true,
                centeredSlides: true,
                spaceBetween: 0,
                speed: 1000,
                mousewheel: {
                    releaseOnEdges: true,
                    eventsTarget: document.querySelector('.home-slider'),
                },
                breakpoints: {
                    1440: {
                        slidesPerView: 4,
                    },
                    1024: {
                        slidesPerView: 3,
                    },
                },
            });
            this.swiper.on('slideChange', () => {
                if (this.swiper.activeIndex === 1) this.$emit('slide-change', true)
                this.activeSlideId = this.swiper.activeIndex
            });
            this.swiper.on('slideChangeTransitionEnd', () => {
                if (this.swiper.activeIndex !== 1) this.$emit('slide-change', false)
            });

        },
        touchStart(e) {
            this.mobileTouchStartY = e.targetTouches?.[0]?.clientY
            this.mobileTouchStartX = e.targetTouches?.[0]?.clientX
        },
        touchEnd(e) {
            if (e.target?.classList?.value?.includes('stopper-slider')) return

            let newY = e.changedTouches?.[0]?.clientY
            let direction = this.mobileTouchStartY - newY

            direction >= 0 ? this.swiper.slideNext() : this.swiper.slidePrev()
        },
        mobileNavigation() {
            document.querySelector('.home-slider').addEventListener('touchstart', this.touchStart, {passive: true})
            document.querySelector('.home-slider').addEventListener('touchend', this.touchEnd, {passive: true})
        },
        showPopUp(item, event) {
            this.$emit('slide-change', true)

            this.$root.$emit('fire_pop_up', {id: item.id, color: item.color, data: item})
        },
        closePopUpEvent() {
            this.$root.$on('close_pop_up', () => {
                this.$emit('slide-change', false)
            })
        },
        sliderEvent(index) {
            if (this.swiper.activeIndex < index) {
                this.swiper.slideNext()
            }
            if (this.swiper.activeIndex > index) {
                this.swiper.slidePrev()
            }
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.initSwiper()
            this.mobileNavigation()
        })
        this.closePopUpEvent()
    },
    watch: {
        activeStepId(newVal, oldValue) {
            if (newVal === 3) {
                let index = oldValue < 3 ? 0 : 2
                setTimeout(() => {
                    if (this.swiper.activeIndex === 0 || this.swiper.activeIndex === 2) {
                        document.querySelectorAll('.swiper-slide')?.[index]?.classList?.add('swiper-slide-active')
                    }
                }, 800)
            } else {
                document.querySelector('.swiper-slide-active')?.classList?.remove('swiper-slide-active')
            }
        }
    },
}
</script>

<style lang="scss" scoped>
$ease: cubic-bezier(.09, 0, .27, 1);

.home-slider {
    height: calc(100vh - 80px);
    top: 80px;
    position: absolute;
    pointer-events: none;
    width: 100%;

    &.active {
        pointer-events: auto;

        ::v-deep .walking-paragraph {
            .text-single {
                opacity: 1;
                transform: translateY(0);
                transition: all .8s $ease;
                transition-delay: .6s;

                &:last-child {
                    transition-delay: .4s;

                }
            }
        }

        .box-container {
            opacity: 1;
            pointer-events: auto;
            transform: translate(-50%, 0) scale(1);
            transition: all .8s .75s $ease;
        }

        .swiper-container {
            transform: translateX(0%);
            transition: all 1s .9s $ease;

            .swiper-slide {
                pointer-events: all !important;
            }
        }
    }

    &.expand {
        .box-container {
            opacity: 1;
            width: calc(100vw - 80px);
            height: calc(100% - 44px);
            background: $green;
            top: 50%;
            transform: translate(-50%, -50%);
            @media only screen and (max-width: 1023px) {
                width: calc(100vw - 42px);
            }
        }

        .content {
            opacity: 0;
        }
    }

    .swiper-container {
        position: relative;
        overflow: hidden;
        transform: translateX(100%);
        transition: all 1s $ease;
        z-index: 2;
        margin-top: 100px;
        height: 100%;
        pointer-events: none;
        @media only screen and (max-width: 1023px) {
            margin-top: 0;
        }
        @media only screen and (max-width: 767px) {
            margin-top: 40px;
        }

        &.first-transition {

        }

        .swiper-wrapper {
            height: 100%;
            transition-timing-function: cubic-bezier(.72, .01, .14, .99) !important;
        }

        .swiper-slide {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 386px;
            background: rgba(25, 36, 61, .4);
            height: 45%;
            border-radius: 10px;
            transform: translateX(-240px);
            transition: all 1s ease !important;
            transition-delay: 0s;
            @media only screen and (max-width: 1439px) and (min-width: 1024px) {
                height: 38%;
            }
            @media only screen and (max-width: 1023px) {
                height: 65%;
            }
            @media only screen and (max-width: 767px) {
                height: 45%;
            }

            &.swiper-slide-active {
                transform: translateX(0);
                transition-delay: .3s;

                .slide-content, .mark {
                    opacity: 1;
                    transition: all .6s .6s $ease;
                }

                .slide-content {
                    &.plans {
                        .plan-img {
                            opacity: 1;
                            transform: translateY(0);
                            transition: all .6s .6s $ease;
                            @for $i from 1 through 3 {
                                &:nth-child(#{$i}) {
                                    transition-delay: $i/5+s;
                                }
                            }
                        }

                        .cursor {
                            width: 32px;
                            left: 60%;
                            top: 50%;
                            opacity: 1;
                            transition: all .6s .6s $ease;
                        }
                    }

                    .envelope-image {
                        opacity: 1;
                        transform: translateY(0) scale(1);
                        transition: all .6s .6s $ease;
                    }

                    .pin {
                        .pin-image {
                            opacity: 1;
                            transform: translateY(0);
                            transition: all .6s .6s $ease;
                        }

                        .circle {
                            opacity: 1;
                            transform: translateY(0);
                            transition: all .6s .6s $ease;
                            @for $i from 1 through 4 {
                                &:nth-child(#{$i}) {
                                    transition-delay: .6+ $i/10+s;
                                }
                            }
                        }
                    }
                }
            }

            &.swiper-slide-prev {
                transition-delay: 0s;
                transform: translateX(-120px) !important;

                .arrow {
                    opacity: 1;
                    transform: translate(-50%, -50%) rotate(180deg);
                    transition: opacity .6s .8s $ease;
                }
            }

            &.swiper-slide-next {
                transition-delay: 0s;
                transform: translateX(120px) !important;

                .arrow {
                    opacity: 1;
                    transition: opacity .6s .8s $ease;
                }
            }

            .slide-content {
                opacity: 0;
                transition: all .6s $ease;
                position: relative;

                .cursor {
                    position: absolute;
                    left: 60%;
                    transform: translate(0, 70%);
                    bottom: 0;
                }

                .pin {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin: 0 auto;
                    @media only screen and (max-width: 1023px) {
                        width: 60%;
                    }

                    .pin-image {
                        opacity: 0;
                        transform: translateY(30px);
                        transition: all .6s $ease;
                        width: 100%;
                        @media only screen and (max-width: 1650px) and (min-width: 1024px) {
                            width: 130px;
                        }
                    }

                    .circle {
                        opacity: 0;
                        transform: translateY(20px);
                        transition: all .6s $ease;
                        transition-delay: 0s;
                    }
                }

                .envelope-image {
                    opacity: 0;
                    transform: translateY(40px) scale(.85);
                    transition: all .6s $ease;
                    @media only screen and (max-width: 1023px) {
                        width: 70%;
                        margin: 0 auto;
                        display: block;
                    }
                }

                &.plans {
                    @media only screen and (max-width: 1023px) {
                        width: 70%;
                    }

                    .plan-img {
                        opacity: 0;
                        transform: translateY(20px);
                        margin-left: 15px;
                        transition-delay: 0s;
                        transition: all .6s $ease;
                        @media only screen and (max-width: 1650px) and (min-width: 1024px) {
                            width: 70px;
                        }
                        @media only screen and (max-width: 1023px) {
                            width: 25%;
                        }
                    }

                    .cursor {
                        width: 32px;
                        left: 100%;
                        top: 100%;
                        opacity: 0;
                        transition: all .6s $ease;
                    }
                }
            }

            .mark {
                position: absolute;
                left: 29px;
                top: 29px;
                width: 60.72px;
                height: 60.72px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                background: $yellow;
                opacity: 0;
                transition: all .6s $ease;

                @media only screen and (max-width: 1650px) and (min-width: 1024px) {
                    left: 20px;
                    top: 20px;
                    width: 40.72px;
                    height: 40.72px;
                    img {
                        width: 25px;
                    }
                }
                @media only screen and (max-width: 1023px) {
                    width: 45.72px;
                    height: 45.72px;
                    .mark-image {
                        width: 25px;
                    }
                }
            }
        }

        .swiper-slide-active ~ .swiper-slide {
            transform: translateX(240px);
            transition-delay: .3s;
        }

        .arrow {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;
            transition: opacity .6s $ease;
        }
    }

    ::v-deep .box-container {
        position: absolute;
        top: 60px;
        left: 50%;
        transform: translate(-50%, 20vh) scale(.8);
        border: 2px solid $blue;
        border-radius: 36px;
        height: 75%;
        width: calc(25% + 80px);
        background: transparent;
        z-index: 1;
        opacity: 0;
        transition: all .6s $ease;
        padding: 40px;
        pointer-events: none;
        @media only screen and (max-width: 1439px) and (min-width: 1024px) {
            width: calc(33.3% + 40px);
            padding: 20px;
        }
        @media only screen and (max-width: 1023px) {
            height: 65%;
            width: 83%;
            top: 0;
            border-radius: 10px;
        }
        @media only screen and (max-width: 767px) {
            height: 45%;
            top: 40px;
        }

        .content {
            position: absolute;
            bottom: 20px;
            pointer-events: none;
            width: 80%;
            @media only screen and (max-width: 1023px) {
                bottom: -36px;
                left: 50%;
                transform: translate(-50%, 100%);
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 90%;
            }

            &.active {
                pointer-events: auto;

                .button {
                    opacity: 1;
                    transform: scale(1);
                    transition: all .6s .6s $ease;
                }

                .typewriter {
                    transition: all .6s .6s $ease;
                    opacity: 1;
                }
            }

            .typewriter {
                opacity: 0;
                transition: all .6s $ease;
            }

            .typed-text {
                @media only screen and (max-width: 767px) {
                    text-align: center;
                }
            }

            .item {
                padding-top: 20px;
                position: relative;
            }

            .stopper-slider {
                position: relative;
                @media only screen and (max-width: 1023px) {
                    z-index: 12;
                }
            }

            .button {
                padding: 10px 21px;
                height: 35px;
                border-radius: 30px;
                box-sizing: border-box;
                color: $black;
                font-weight: 700;
                font-size: 13px;
                text-transform: uppercase;
                display: flex;
                align-items: center;
                justify-content: center;
                opacity: 0;
                transform: scale(.85);
                transition: all .6s $ease;
                cursor: pointer;
                @media only screen and (max-width: 1439px) and (min-width: 1024px) {
                    margin-top: 15px;
                }
            }
        }
    }

    ::v-deep .walking-paragraph {
        .text-single {
            opacity: 0;
            transform: translateY(30px);
            transition: all .6s $ease;
            transition-delay: 0s;
        }
    }
}
</style>
