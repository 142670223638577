export default {
    namespaced: true, state: () => ({
        _size: false
    }), actions: {
        setSize({commit}, value) {
            commit('SET_SIZE', value)
        },
    }, mutations: {
        SET_SIZE(state, value) {
            state._size = value
        },
    }, getters: {
        size(state) {
            return state._size
        }
    },
}
