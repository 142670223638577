import fn from "@/helpers/func";

export default {
    namespaced: true, state: () => ({
        _campaignsStep: 'LIST',
        _campaigns: null,
        _usedCounties: null,
    }), getters: {
        campaignsStep(state) {
            return state._campaignsStep
        },
        campaigns(state) {
            return state._campaigns
        },
        usedCounties(state) {
            return state._usedCounties
        },

    }, actions: {
        setStep({commit}, data) {
            document.getElementById('tag-match-inner').scrollTop = 0;

            commit('SET_CAMPAIGNS_STEP', data)
        },
        async getCampaigns({commit}) {
            return fn.adminPost("tagAndMatch/getCampaigns").then((res) => {
                if (res.status === 200) commit('SET_CAMPAIGNS', res.data?.list)
            })
        },
        async getUsedCounties({commit}) {
            return fn.postData("main/getUsedCounties").then((res) => {
                if (res.status === 200) commit('SET_USED_COUNTIES', res.data)
            })
        },
        deletePlane({commit}, item) {
            return fn.adminPost(`tagAndMatch/updatePlanStatus?id=${item.id}`)
        }

    }, mutations: {
        SET_CAMPAIGNS_STEP(state, data) {
            state._campaignsStep = data
        },
        SET_CAMPAIGNS(state, data) {
            state._campaigns = data
        },
        SET_USED_COUNTIES(state, data) {
            state._usedCounties = data
        },
    },
}
