<template>
    <div :class="{expand:expand}" class="walking-paragraph">
        <div class="text-marquee">
            <div class="text-single">
                <span class="text js-text">pick up business location and</span>
                <span class="text js-text">pick up business location and</span>
                <span class="text js-text">pick up business location and</span>
            </div>
            <div class="text-single">
                <span class="text js-text">pick up business location and</span>
                <span class="text js-text">pick up business location and</span>
                <span class="text js-text">pick up business location and</span>
            </div>
        </div>
    </div>
</template>

<script>
import {gsap} from "gsap";

export default {
    props: {
        expand: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        horizontalLoop(items, config) {
            items = gsap.utils.toArray(items);
            config = config || {};
            let tl = gsap.timeline({
                    repeat: config.repeat,
                    paused: config.paused,
                    defaults: {ease: "none"},
                    onReverseComplete: () => tl.totalTime(tl.rawTime() + tl.duration() * 100)
                }),
                length = items.length,
                startX = items[0].offsetLeft,
                times = [],
                widths = [],
                xPercents = [],
                curIndex = 0,
                pixelsPerSecond = (config.speed || 1) * 100,
                snap = config.snap === false ? v => v : gsap.utils.snap(config.snap || 1), // some browsers shift by a pixel to accommodate flex layouts, so for example if width is 20% the first element's width might be 242px, and the next 243px, alternating back and forth. So we snap to 5 percentage points to make things look more natural
                totalWidth, curX, distanceToStart, distanceToLoop, item, i;
            gsap.set(items, { // convert "x" to "xPercent" to make things responsive, and populate the widths/xPercents Arrays to make lookups faster.
                xPercent: (i, el) => {
                    let w = widths[i] = parseFloat(gsap.getProperty(el, "width", "px"));
                    xPercents[i] = snap(parseFloat(gsap.getProperty(el, "x", "px")) / w * 100 + gsap.getProperty(el, "xPercent"));
                    return xPercents[i];
                }
            });
            gsap.set(items, {x: 0});
            totalWidth = items[length - 1].offsetLeft + xPercents[length - 1] / 100 * widths[length - 1] - startX + items[length - 1].offsetWidth * gsap.getProperty(items[length - 1], "scaleX") + (parseFloat(config.paddingRight) || 0);
            for (i = 0; i < length; i++) {
                item = items[i];
                curX = xPercents[i] / 100 * widths[i];
                distanceToStart = item.offsetLeft + curX - startX;
                distanceToLoop = distanceToStart + widths[i] * gsap.getProperty(item, "scaleX");
                tl.to(item, {
                    xPercent: snap((curX - distanceToLoop) / widths[i] * 100),
                    duration: distanceToLoop / pixelsPerSecond
                }, 0)
                    .fromTo(item, {xPercent: snap((curX - distanceToLoop + totalWidth) / widths[i] * 100)}, {
                        xPercent: xPercents[i],
                        duration: (curX - distanceToLoop + totalWidth - curX) / pixelsPerSecond,
                        immediateRender: false
                    }, distanceToLoop / pixelsPerSecond)
                    .add("label" + i, distanceToStart / pixelsPerSecond);
                times[i] = distanceToStart / pixelsPerSecond;
            }

            function toIndex(index, vars) {
                vars = vars || {};
                (Math.abs(index - curIndex) > length / 2) && (index += index > curIndex ? -length : length); // always go in the shortest direction
                let newIndex = gsap.utils.wrap(0, length, index),
                    time = times[newIndex];
                if (time > tl.time() !== index > curIndex) { // if we're wrapping the timeline's playhead, make the proper adjustments
                    vars.modifiers = {time: gsap.utils.wrap(0, tl.duration())};
                    time += tl.duration() * (index > curIndex ? 1 : -1);
                }
                curIndex = newIndex;
                vars.overwrite = true;
                return tl.tweenTo(time, vars);
            }

            tl.next = vars => toIndex(curIndex + 1, vars);
            tl.previous = vars => toIndex(curIndex - 1, vars);
            tl.current = () => curIndex;
            tl.toIndex = (index, vars) => toIndex(index, vars);
            tl.times = times;
            if (config.reversed) {
                tl.vars.onReverseComplete();
                tl.reverse();
            }
            return tl;
        },
        setMotion() {
            let loops = gsap.utils.toArray('.text-single').map((line, i) => {
                const links = line.querySelectorAll(".js-text");
                return this.horizontalLoop(links, {
                    repeat: -1,
                    speed: .2 + i * 0.1,
                    reversed: false,
                    paddingRight: parseFloat(gsap.getProperty(links[0], "marginRight", "px"))
                })
            });

            let currentScroll = 0;
            let scrollDirection = 1;

            window.addEventListener("scroll", () => {
                // let direction = (window.pageYOffset > currentScroll) ? 1 : -1;
                // if (direction !== scrollDirection) {
                //   loops.forEach(tl => gsap.to(tl, {timeScale: direction, overwrite: true}));
                //   scrollDirection = direction;
                // }
                // currentScroll = window.pageYOffset;
            },{passive: true});
        }
    },
    mounted() {
        this.setMotion()
    }
}
</script>

<style lang="scss" scoped>
$ease: cubic-bezier(.09, 0, .27, 1);

.walking-paragraph {
    position: absolute;
    top: 30%;
    left: 0;
    pointer-events: none;
    @media only screen and (max-width: 1650px) and (min-width: 1440px) {
        top: 40%;
    }

    &.expand {
        opacity: 1 !important;
        z-index: 9;

        .text-single {
            opacity: 1 !important;
            transform: translateY(0);
            transition: all .8s $ease;
            transition-delay: .6s;

            &:last-child {
                transition-delay: .4s;
            }
        }

        .text {
            color: #0D1321 !important;
        }
    }

    .text-marquee {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-x: hidden;
    }

    .text-single {
        position: relative;
        white-space: nowrap;
        padding: 0;
        margin: 0;
        will-change: transform;


        &:last-child {
            .text {
                opacity: 0.03
            }
        }
    }

    .text {
        padding: 0;
        font-size: 144px;
        font-weight: 400;
        display: inline-block;
        font-family: 'Gilda Display';
        margin-right: 2rem;
        text-transform: uppercase;
        color: rgb(250, 250, 250);
        opacity: 0.05;
        @media only screen and (max-width: 1650px) and (min-width: 1440px) {
            font-size: 110px;
        }
        @media only screen and (max-width: 1439px) and (min-width: 1024px) {
            font-size: 80px;
        }
        @media only screen and (max-width: 1023px)  {
            font-size: 75px;
        }
    }
}
</style>
