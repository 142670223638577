<template>
    <div :class="{scroll:userDashboard}"
         id="tag-match-inner"
         class="tag-match-inner">
        <header-component/>
        <!--        //v-if="!loader"-->
        <div v-if="indx"
             :class="{scroll:userDashboard}"
             data-scroll-container class="router-view-inner">
            <router-view></router-view>
            <footer-component/>
        </div>
        <transition name="loader">
            <loader v-if="loader"/>
        </transition>
        <div v-if="isHome && homeStepId<3" class="scroll-imitation">
            <div class="first-scroll-track">
            </div>
            <div class="first-scroll-thumb"></div>
        </div>
        <pop-up-component id="discover_pop_up" component="DiscoverPopUp"/>
        <portal-target name="main">
        </portal-target>
    </div>
</template>

<script>
import Loader from './components/loader/indexComponent';
import HeaderComponent from "@/components/HeaderComponent";
import FooterComponent from "@/components/FooterComponent";
import PopUpComponent from '@/components/popups/PopUpComponent';
import { mapActions, mapGetters } from "vuex";
import get from 'lodash/get';
import router from "@/router";

export default {
    name: 'App',
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            scroller: 'scroll/scroller',
            loader: 'menu/loader',
            indx: 'menu/indx',
            homeStepId: 'scroll/getHomeStepId',
            current_content: 'menu/current_content',
        }),
        innerSeo() {
            return get(
                this.current_content,
                `primary.data.list[0].seo_title_en}`
            );
        },
        selectedMenu() {
            let path = this.$route?.path.split('/')?.[1];

            return this.indx?.menus?.find(item => {
                return item.url_slug === path;
            }) || {};
        },
        seoTitle() {
            return this.selectedMenu.seo?.title
                ? this.selectedMenu.seo?.title
                : this.selectedMenu?.title
                    ? this.selectedMenu?.title
                    : "tag&match";
        },
        keywords() {
            return this.selectedMenu.seo?.keywords || this.indx?.website_meta_keywords;
        },
        contentDescription() {
            return this.selectedMenu.seo?.en?.description;
        },
        websiteTitle() {
            return this.indx?.siteSettings?.website_title || "";
        },
        outerSeoTitleWrapper() {
            if (this.websiteTitle.length) {
                return this.seoTitle + " | " + this.websiteTitle;
            } else {
                return this.seoTitle;
            }
        },
        headTitle() {
            let firstTitle = this.selectedMenu.seo?.en?.title || this.websiteTitle;
            if (!this.innerSeo) {
                return (
                    firstTitle +
                    " | " +
                    this.websiteTitle
                );
            } else {
                return this.outerSeoTitleWrapper;
            }
        },
        isHome() {
            return this.$route.name === 'home';
        },
        userDashboard() {
            return this.$route.name === 'UserDashboard'
                || this.$route.name === 'map'
                || this.$route.name === 'calculator'
                || this.$route.name === 'contactUs'
                || this.$route.name === 'signUp';
        },
    },
    metaInfo() {
        return {
            title: this.headTitle,
            meta: [
                {
                    name: `description`,
                    content: this.contentDescription,
                },
                {
                    name: "keyword",
                    content: this.keywords,
                },
            ],
        };
    },
    components: {
        HeaderComponent,
        Loader,
        PopUpComponent,
        FooterComponent
    },
    methods: {
        ...mapActions({
            setScroll: 'scroll/setScroll',
            toggleMobileScroll: 'scroll/toggleMobileScroll',
            setSize: 'resize/setSize'
        }),
        resize() {
            this.setSize(window.innerWidth);
        },
        resizeEvent() {
            window.addEventListener('resize', this.resize, { passive: true });
        },
    },
    mounted() {
        this.resizeEvent();
        this.setSize(window.innerWidth);
    }
};
</script>

<style lang="scss">
$seaGreen: #08B86F;
.tag-match-inner {

}

.vue-portal-target {
    position: fixed;
    z-index: 99;
    left: 0;
    top: 0;
}

* {
    box-sizing: border-box;
}

body, html {
    margin: 0;
    padding: 0;
    position: relative;
    min-height: 100vh;
    overflow: hidden;
    background: #0D1321;
    @media only screen and (max-width: 1023px) {
        overflow-y: auto;
    }
}

html.has-scroll-smooth {
    backface-visibility: hidden;
    transform: translateZ(0);
}

h2, h1, a, span, p, .button {
    display: block;
    margin: 0;
    padding: 0;
    font-family: Inter, serif;
}

.router-view-inner {
    //height: 100vh;
    //padding-top: 122px;
    //position: fixed;
    //width: 100vw;
    //height: calc(100vh - 122px) !important;
    //min-height: unset !important;
    &.scroll {
        transform: unset !important;
    }
}

.tag-match-inner {
    background: $black;
    min-height: 100vh;
    height: 100vh;
    overflow: hidden;
    @media only screen and (max-width: 1023px) {
        height: auto;
    }

    &.scroll {
        overflow-y: scroll;
        @media only screen and (max-width: 1023px) {
            overflow: unset;
        }

        &::-webkit-scrollbar {
            width: 4px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: $black;
        }


    }

    &.close {
        overflow: hidden;
        height: 100vh;
    }

    .scroll-imitation {
        position: fixed;
        bottom: 30px;
        left: 50%;
        transform: translateX(-50%);

        .first-scroll-thumb {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: $yellow;
            position: absolute;
            left: 50%;
            top: 7px;
            margin-left: -3px;
            animation: scrollOne 2s both infinite;
        }

        .first-scroll-track {
            width: 20px;
            height: 33px;
            border: 1px solid rgba(255, 255, 255, 0.3);
            border-radius: 50px;
            position: relative;
            animation: scrollTwo 2s both 0s infinite;
        }
    }

    @keyframes scrollTwo {
        from {
            transform: translate(0, 0);
        }
        25% {
            transform: translate(0, 10px);
        }
        30% {
            transform: translate(0, 0);
        }
        to {
            transform: translate(0, 0);
        }
    }
    @keyframes scrollOne {
        from {
            transform: translate(0, 0);
            opacity: 1;
        }
        25% {
            transform: translate(0, 550%);
            opacity: 0;
        }
        26% {
            transform: translate(0, -150%);
            opacity: 0;
        }
        50% {
            transform: translate(0, 0%);
            opacity: 1;
        }
        100% {
            transform: translate(0, 0%);
            opacity: 1;
        }
    }
}

/* loader transition */
.loader-enter-active,
.loader-leave-active {
    transition: opacity 0.5s ease;
}

.loader-enter-from,
.loader-leave-to {
    opacity: 0;
}
</style>
