import LocomotiveScroll from "locomotive-scroll";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";

export default {
    namespaced: true, state: () => ({
        _scroller: null, _scrollTop: 0, homeStepId: 0
    }), actions: {
        setHomeStepId({commit}, val) {
            commit('SET_HOME_STEP_ID', val)
        }, onScroll({commit}, e) {
            commit('SET_SCROLL_TOP', e.scroll.y)
            ScrollTrigger.update
        }, setScroll({commit, dispatch}) {
            if (window.innerWidth < 1024) return
            let scroller = new LocomotiveScroll({
                el: document.querySelector('[data-scroll-container]'), smooth: true, lerp: 0.03,
            });
            gsap.registerPlugin(ScrollTrigger);
            scroller.on("scroll", (e) => {
                dispatch('onScroll', e)
            });
            ScrollTrigger.scrollerProxy('.home-view', {
                scrollTop(value) {
                    return arguments.length ? scroller.scrollTo(value, 0, 0) : scroller.scroll.instance.scroll.y;
                }, getBoundingClientRect() {
                    return {
                        left: 0, top: 0, width: window.innerWidth, height: window.innerHeight
                    };
                },
            });
            ScrollTrigger.addEventListener("refresh", () => scroller.update(),{passive: true});
            ScrollTrigger.refresh();
            commit('SET_SCROLLER', scroller)
        }, toggleMobileScroll({commit}, value) {
            let html = document.querySelector('html')
            let body = document.querySelector('body')
            let cont = document.querySelector('.tag-match-inner')
            if (value) {
                body.style.overflow = 'hidden'
                html.style.overflow = 'hidden'
                cont.classList.add('close')
            } else {
                body.style.overflowY = 'auto'
                html.style.overflowY = 'auto'
                cont.classList.remove('close')
            }
        }
    }, mutations: {
        SET_SCROLLER(state, scroller) {
            state._scroller = scroller
        }, SET_SCROLL_TOP(state, top) {
            state._scrollTop = top
        }, SET_HOME_STEP_ID(state, val) {
            state.homeStepId = val
        },
    }, getters: {
        scroller(state) {
            return state._scroller
        }, scrollTop(state) {
            return state._scrollTop
        }, getHomeStepId(state) {
            return state.homeStepId
        }
    }
}
