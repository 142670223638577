export default {
    namespaced: true, state: () => ({
        _activeCounty: null,
        _coordinates: {},
        _activeViewBox: '0 0 1460.156 915.307'
    }), actions: {
        setActiveCounty({commit}, county) {
            commit('SET_ACTIVE_COUNTY', county)
        },
        setCoordinate({commit}, coordinate) {
            commit('SET_COORDINATE', coordinate)
        },
        setViewBox({commit}, viewBox) {
            commit('SET_VIEW_BOX', viewBox)
        },
    }, mutations: {
        SET_ACTIVE_COUNTY(state, county) {
            state._activeCounty = county
        },
        SET_COORDINATE(state, coordinate) {
            state._coordinates = coordinate
        },
        SET_VIEW_BOX(state, viewBox) {
            state._activeViewBox = viewBox
        },
    }, getters: {
        activeCounty(state) {
            return state._activeCounty
        },
        coordinates(state) {
            return state._coordinates
        },
        activeViewBox(state) {
            return state._activeViewBox
        },
    },
}
