<template>
    <div :class="{active:activeStepId===4}" class="choose-plane">
        <div class="plane-inner">
            <div class="header-title" v-html="content.title"></div>
            <div v-html="content.teaser" class="title"></div>
            <plane-item-component @toggle="toggle"/>
        </div>
    </div>
</template>

<script>
import PlaneItemComponent from '../components/PlaneItemComponent'
import {mapGetters} from "vuex";

export default {
    props: {
        activeStepId: {
            type: Number,
            default: 0
        }
    },
    components: {
        PlaneItemComponent
    },
    computed: {
        ...mapGetters({
            indx: 'menu/indx',
        }),
        content() {
            return this.indx?.widgets?.['choose-a-plan'] || {}
        },
    },
    methods: {
        toggle(value) {
            this.$emit('change', value)
        },

    }
}
</script>

<style lang="scss" scoped>
$ease: cubic-bezier(.09, 0, .27, 1);

::v-deep.choose-plane {
    position: absolute;
    height: calc(100vh - 80px);
    top: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    pointer-events: none !important;
    @media only screen and (max-width: 767px) {

    }

    &.active {
        pointer-events: auto !important;
        z-index: 99;

        .header-title, .title {
            opacity: 1;
            transition: opacity .6s .8s $ease;
        }

        .plane-items {
            .item {
                opacity: 1;
                transform: translateY(0);
                transition-delay: .8s;

                &:first-child {
                    transition-delay: .6s;
                }
            }
        }
    }

    .plane-inner {
        width: 60%;
        @media only screen and (max-width: 1650px) and (min-width: 1440px) {
            width: 70%;
        }
        @media only screen and (max-width: 1439px) and (min-width: 1024px) {
            width: 80%;
        }
        @media only screen and (max-width: 1023px) {
            width: 85%;
        }
    }

    .header-title {
        font-weight: 900;
        font-size: 55px;
        color: #FFFFFF;
        opacity: 0;
        transition: opacity .6s $ease;
        display: flex;
        align-items: center;
        @media only screen and (max-width: 1650px) and (min-width: 1024px) {
            font-size: 40px;
        }
        @media only screen and (max-width: 1023px) and (min-width: 768px) {
            font-size: 34px;
        }
        @media only screen and (max-width: 767px) {
            font-size: 21px;
        }
        @media only screen and (max-width: 1023px) {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
        }

        span {
            color: $black;
            margin-left: 10px;
        }
    }

    .title {
        font-weight: 400;
        font-size: 21px;
        line-height: 25px;
        margin-top: 10px;
        color: $black;
        opacity: 0;
        transition: opacity .6s $ease;
        display: inline-block;
        margin-bottom: 0;

        @media only screen and (max-width: 1650px) and (min-width: 1440px) {
            font-size: 18px;
        }
        @media only screen and (max-width: 1023px) {
            text-align: center;
            font-size: 16px;
        }

        p {
            margin-bottom: 0;
        }

        span {
            color: white;
            display: inline-block;

        }
    }

    .plane-items {
        .item {
            opacity: 0;
            transform: translateY(40%);
            @media only screen and (max-width: 767px) {
                height: 320px;
                .item-title {
                    font-size: 14px;
                    line-height: 22px;
                }
                .price {
                    font-size: 21px;
                    text-align: center;
                }
            }
        }
    }

    .swiper-container {
        .swiper-wrapper {
            @media only screen and (max-width: 1023px) {
                //flex-direction: column;
                //align-items: center;
                //justify-content: center;
                //grid-gap: 20px;
                //transform: translate3d(0px, 0px, 0px) !important;
            }
        }
    }
}
</style>
