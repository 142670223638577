<template>
    <div :class="{scrolled,logged:user}" class="header">
        <div :class="{active:showNavbar}" class="menu-nav-bar">
            <div class="menu-list">
                <div v-for="(item,index) in menu"
                     :key="index"
                     @click="changeRoute(item.url_slug)"
                     :class="{active:setActiveMenu(item)}" class="item">{{ item.title }}
                </div>
            </div>
            <div v-if="!user" class="mobile-buttons">
                <log-in-button/>
                <router-link to="/sign-up" class="sigh-up button">Sign up</router-link>
            </div>
        </div>
        <div :class="{active:showNavbar}" class="main-content">
            <div @click="changeRoute('/')" class="logo">
                <img src="../assets/images/loading.webp" class="image" alt="loading">
                <img src="../assets/images/tag_match.webp" class="image tag-match" alt="tag">
            </div>
            <div @click="showMenu" class="menu">
                <div v-if="!showNavbar" key="burger" class="dots-container">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                </div>
                <svg v-else width="22" key="close" height="22" viewBox="0 0 22 22" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M20.7513 4.508C21.7276 3.53169 21.7276 1.94878 20.7513 0.972467C19.775 -0.00384331 18.1921 -0.00384331 17.2158 0.972467L10.7438 7.44451L4.27172 0.972479C3.29541 -0.00383192 1.7125 -0.00383183 0.736189 0.972479C-0.240122 1.94879 -0.240122 3.5317 0.736189 4.50801L7.20822 10.98L0.736139 17.4521C-0.240172 18.4284 -0.240171 20.0113 0.736139 20.9877C1.71245 21.964 3.29536 21.964 4.27167 20.9877L10.7438 14.5156L17.2158 20.9877C18.1922 21.964 19.7751 21.964 20.7514 20.9877C21.7277 20.0114 21.7277 18.4284 20.7514 17.4521L14.2793 10.98L20.7513 4.508Z"
                          fill="#F3AA00"/>
                </svg>
            </div>
            <div v-if="!user" class="registration-buttons">
                <log-in-button/>
                <animated-button
                    class="sigh-up button"
                    @click.native="$router.push({path:'/sign-up'})"
                    :title="fn.tr('Sign up')"/>
            </div>
            <router-link to="/user-dashboard" v-else
                         class="user-button">Hi {{ user.fullname | split }}
            </router-link>
        </div>
    </div>
</template>

<script>
import LogInButton from '@/components/forms/LogInButton'
import AnimatedButton from '@/components/custom/AnimatedButton'
import {mapActions, mapGetters} from "vuex";

export default {
    data() {
        return {
            showNavbar: false,
            scrolled: false,
        }
    },
    components: {
        LogInButton,
        AnimatedButton
    },
    computed: {
        ...mapGetters({
            indx: 'menu/indx',
            user: 'user/user'
        }),
        menu() {
            //sorted
            return this.indx?.menus?.map(item => ({
                ...item,
                url_slug: item.url_slug === '' ? '/' : item.url_slug,
                title: item.titles?.en?.title
            }))?.sort((a, b) => a.sort - b.sort) || []
        },
        activeRoute() {
            return this.$route?.path?.split('/')?.[1]
        }
    },
    filters: {
        split: function (value) {
            if (!value) return ''

            return value?.split(' ')?.[0]
        }
    },
    methods: {
        changeRoute(path) {
            if (this.$route.path === path) this.$router.go()
            this.$router.push({path: path || '/'})
        },
        scrollAnimation(e) {
            this.scrolled = true
        },
        mouseWheelEvent() {
            window.addEventListener('wheel', this.scrollAnimation,{passive: true})
            window.addEventListener('touchstart', this.scrollAnimation,{passive: true})
        },
        showMenu() {
            this.showNavbar = !this.showNavbar
            this.scrolled = true
        },
        setActiveMenu(item) {
            return item.url_slug === '/' ? this.$route.name === 'home' : item.url_slug === this.activeRoute
        }
    },
    watch: {
        $route(to, from) {
            this.showNavbar = false;
        }
    },
    mounted() {
        this.mouseWheelEvent();
    },
    destroyed() {
        window.removeEventListener('wheel', this.scrollAnimation)
    }
}
</script>

<style lang="scss" scoped>
$ease: cubic-bezier(.35, 0, .31, 1);
.header {
    position: fixed;
    width: 100%;
    z-index: 100;

    &.scrolled {
        .main-content {
            padding: 10px 50px;
            background: #070B16;
            @media only screen and (max-width: 767px) {
                padding: 10px 21px;
            }
        }

        .tag-match {
            opacity: 0;
            width: 0 !important;

        }
    }

    &.logged {
        .main-content {
            &.active {
                transform: translateY(calc(100% + 0px)) !important;
                @media only screen and (max-width: 767px) {
                    transform: translateY(calc(100% + 189px)) !important;
                }
            }
        }

        .menu-nav-bar {
            padding-bottom: 27.5px !important;
        }

        .menu {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            @media only screen and (max-width: 1023px) {
                position: relative;
                left: unset;
                transform: unset;
            }
        }
    }

    .mobile-burger {
        display: none;
        @media only screen and (max-width: 1023px) {
            display: block;
        }

        #toggle {
            display: none;
        }

        .hamburger {

        }

        .hamburger div {
            position: relative;
            width: 3em;
            height: 5px;
            border-radius: 3px;
            background-color: $yellow;
            margin-top: 8px;
            transition: all 0.3s ease-in-out;
        }

        #toggle:checked + .hamburger .top-bun {
            transform: rotate(-45deg);
            margin-top: 25px;
        }

        #toggle:checked + .hamburger .bottom-bun {
            opacity: 0;
            transform: rotate(45deg);
        }

        #toggle:checked + .hamburger .meat {
            transform: rotate(45deg);
            margin-top: -5px;
        }

        #toggle:checked + .hamburger + .nav {
            top: 0;
            transform: scale(1);
        }
    }

    .logo {
        cursor: pointer;
        @media only screen and (max-width: 1023px) {
            img {
                &:first-child {
                    width: 50px;
                }

                &:last-child {
                    width: 100px;
                }
            }
        }
    }

    .main-content {
        padding: 50px 50px 11px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: all .45s $ease;
        @media only screen and (max-width: 1023px) and (min-width: 768px) {
            padding: 25px 50px 11px;
        }
        @media only screen and (max-width: 767px) {
            padding: 21px 21px 0;
        }

        &.active {
            transform: translateY(calc(100% + 0px));
            @media only screen and (max-width: 1023px) {
                transform: translateY(calc(100% + 99px));
            }
            @media only screen and (max-width: 767px) {
                transform: translateY(calc(100% + 269px)) !important;
            }
        }
    }

    .tag-match {
        opacity: 1;
        transition: opacity .45s $ease;
    }

    .user-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 121px;
        height: 40px;
        background: #08B86F;
        border-radius: 30px;
        cursor: pointer;
        font-weight: 700;
        font-size: 16px;
        text-decoration: none;
        color: $black;
    }

    .menu-nav-bar {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        padding: 27.5px 0;
        background: $green;
        position: absolute;
        left: 0;
        top: 0;
        transform: translateY(-100%);
        transition: transform .45s $ease;
        @media only screen and (max-width: 1023px) {
            padding-bottom: 0;
        }

        &.active {
            transform: translateY(0);
        }


        .menu-list {
            display: flex;
            align-items: center;
            list-style-type: none;
            margin-bottom: 0;
            padding-left: 0;
            @media only screen and (max-width: 767px) {
                flex-direction: column;
            }

            .item {
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 34px;
                color: $black;
                margin-left: 20px;
                cursor: pointer;
                @media only screen and (max-width: 767px) {
                    margin-left: 0;
                }

                &.active {
                    color: $white;
                    text-decoration: underline;
                }

                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }

    .menu {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        cursor: pointer;


        .dots-container {
            display: flex;
            align-items: center;
            cursor: pointer;

            &:hover {
                .dot {
                    &:first-child {
                        transform: translateX(-5px);
                    }

                    &:last-child {
                        transform: translateX(5px);
                    }
                }
            }
        }

        .dot {
            border-radius: 50%;
            width: 10px;
            height: 10px;
            background: $white;
            margin-left: 5px;
            transition: all .45s cubic-bezier(.23, -0.01, .08, 1);

            &:first-child {
                margin-left: 0;
            }
        }
    }

    .registration-buttons, .mobile-buttons {
        display: flex;
        align-items: center;
        @media only screen and (max-width: 1023px) {
            display: none;
        }

        .button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 114px;
            height: 40px;
            border-radius: 30px;
            font-weight: 700;
            font-size: 16px;
            line-height: 34px;
            text-transform: uppercase;
            cursor: pointer;
            text-decoration: none;
            border: none;

            &.sigh-up {
                color: $blue;
                background: transparent;
                border: 2px solid $blue;
                margin-left: 10px;
            }
        }
    }

    .mobile-buttons {
        display: none;
        background: #1C2A40;
        width: 100%;
        padding: 22px 26px;
        margin-top: 24px;
        justify-content: center;
        @media only screen and (max-width: 1023px) {
            display: flex;
        }
    }

}
</style>
