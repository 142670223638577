import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import func from "./helpers/func";
import PortalVue from 'portal-vue'
import Meta from 'vue-meta'
import Print from 'vue-print-nb'

Vue.use(Print);
Vue.use(Meta)
Vue.use(PortalVue)
Vue.config.productionTip = false
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import vClickOutside from 'v-click-outside'

Vue.use(vClickOutside)


const plugin = {
    install() {
        Vue.prototype.fn = func
    }
}

Vue.use(plugin)

new Vue({
    router, store, render: h => h(App), beforeCreate: function () {
    },
}).$mount('#app')
