<template>
    <div class="plane-items">
        <div class="swiper-container swiper-container-plans">
            <div class="swiper-wrapper">
                <div v-for="(item,index) in plans"
                     :key="index" class="swiper-slide">
                    <div class="item">
                        <div class="item-header">{{ item.title }}</div>
                        <div class="line"></div>
                        <div class="price-inner">
                            <span class="item-title">{{ item.teaser }}</span>
                            <span class="price" v-html="item.price"></span>
                        </div>
                        <div class="item-title">{{ item.desc }}</div>
                        <animated-button
                            @click.native="showPopUp(item)"
                            :title="item.buttonTitle"/>
                    </div>
                </div>

            </div>
        </div>

    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Swiper from "swiper";
import AnimatedButton from '@/components/custom/AnimatedButton'

export default {
    props: {
        dashboard: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            timeOut: null
        }
    },
    components: {
        AnimatedButton
    },
    computed: {
        ...mapGetters({
            indx: 'menu/indx'
        }),
        plans() {
            let basic = this.indx?.widgets?.['choose-a-plan-basic'] || {}
            let premium = this.indx?.widgets?.['choose-a-plan-premium'] || {}
            return [
                {
                    ...basic,
                    buttonTitle: basic?.button?.title,
                    premium: false
                },
                {
                    ...premium,
                    buttonTitle: premium?.button?.title,
                    premium: true
                },
            ]
        }
    },
    methods: {
        ...mapActions({
            setPremium: 'forms/setPremium'
        }),
        initSwiper() {
            let swiper = new Swiper(".swiper-container-plans", {
                slidesPerView: '1.1',
                spaceBetween: 20,
                grabCursor: true,
                breakpoints: {
                    1024: {
                        slidesPerView: 2,
                    },
                },
            });

            swiper.on('sliderMove', () => {
                this.$emit('toggle', true)
            });
            swiper.on('touchEnd', () => {
                if (this.timeOut) clearTimeout(this.timeOut)

                this.timeOut = setTimeout(() => {
                    this.$emit('toggle', false)
                }, 500)
            });
        },
        showPopUp(item) {
            this.dashboard ? this.$emit('openPlane') : this.$root.$emit('fire_pop_up', {id: 'order/OrderPopUp'})

            this.setPremium(item.premium)
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.initSwiper()
        })
    }
}
</script>

<style lang="scss" scoped>
$ease: cubic-bezier(.09, 0, .27, 1);

.plane-items {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media only screen and (max-width: 767px) {
        flex-direction: column;
    }

    .swiper-container-plans {
        width: 100%;
    }

    ::v-deep .item {
        padding: 35px 30px;
        display: flex;
        align-items: center;
        flex-direction: column;
        background: #FFFFFF !important;
        border-radius: 10px;
        color: $black;
        transition: all .8s $ease;
        transition-delay: .1s;
        height: 460px;
        justify-content: space-between;
        @media only screen and (max-width: 1650px) and (min-width: 1024px) {
            padding: 21px;
            height: 400px;
        }
        @media only screen and (max-width: 1023px) {
            height: 359px;
            padding: 15px 10px;

        }
        @media only screen and (max-width: 767px) {
            width: 100%;
            height: 380px;
        }

        &:first-child {
            margin-right: 30px;
            transition-delay: 0s;
            @media only screen and (max-width: 767px) {
                margin-right: 0;
            }

            .line {
                background: $green;

            }
        }

        .item-header {
            font-weight: 700;
            font-size: 21px;
            line-height: 28px;
        }

        .line {
            margin-top: 18px;
            width: 30px;
            height: 2px;
            background: $red;
            @media only screen and (max-width: 1280px) {
                margin-top: 10px;
            }
        }

        .price-inner {
            margin-top: 0;
            margin-bottom: 0;

            span {
                display: block;
            }

            .price {
                font-weight: 900;
                font-size: 55px;
                @media only screen and (max-width: 1280px) {
                    font-size: 34px;
                }

                span {
                    display: inline;
                    font-size: 34px;
                    font-weight: 500;
                }

                p {
                    margin-bottom: 0;
                }
            }

        }

        .item-title {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
        }

        .button {
            background: $black;
            border-radius: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 700;
            font-size: 16px;
            color: white;
            text-transform: uppercase;
            margin-top: 46px;
            height: 40px;
            padding: 10px 30px;
            cursor: pointer;
            transition: all .45s ease;
            @media only screen and (max-width: 1650px) and (min-width: 768px) {
                margin-top: 21px;
            }
            @media only screen and (max-width: 767px) {
                margin-top: 20px;
            }
        }
    }
}
</style>
