import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from "@/store";
import func from "@/helpers/func";

Vue.use(VueRouter)

const routes = [
    {
    path: '/', name: 'home', component: HomeView
}, {
    path: '/about-us', name: 'about', component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
}, {
    path: '/how-start',
    name: 'howStart',
    component: () => import(/* webpackChunkName: "about" */ '../views/HowStartView')
}, {
    path: '/map', name: 'map', component: () => import(/* webpackChunkName: "about" */ '../views/Map')
}, {
    path: '/calculator',
    name: 'calculator',
    component: () => import(/* webpackChunkName: "about" */ '../views/ExpensesCalculate')
}, {
    path: '/contact', name: 'contactUs', component: () => import(/* webpackChunkName: "about" */ '../views/ContactUs')
}, {
    path: '/sign-up',
    name: 'signUp',
    meta: {guest: true},
    component: () => import(/* webpackChunkName: "about" */ '../views/SignUp')
}, {
    path: '/user-dashboard',
    name: 'UserDashboard',
    meta: {requiresAuth: true},
    component: () => import(/* webpackChunkName: "about" */ '../views/UserDashboard')
},]

const router = new VueRouter({
    mode: 'history', routes
})

// page enter validation
function setUser(newUser) {
    let user = store.getters["auth/getUser"]

    if (!user) {
        store.commit('auth/SET_USER', newUser)
    }
}

function userExistNavigation(to, from, next, user) {
    if (to.matched.some(record => record.meta.verify) && user.signInUserSession.accessToken.payload['cognito:groups']?.includes('KYC')) {
        next({
            path: '/',
        })
    }
    if (to.matched.some(record => record.meta.requiresVerification) && !user.signInUserSession.accessToken.payload['cognito:groups']?.includes('KYC')) {
        next({
            path: '/verification',
        })
    }
    setUser(user)
    if (!user) {
        next({
            path: '/sign-in',
        })
    } else {
        next()
    }
}

function AuthNavigationGuard(to, from, next) {
    const user = store.getters["user/user"];

    user ? next() : next({path: '/sign-up'})
}

function guestNavigationGuard(to, from, next) {
    let user = store.getters["user/user"]
    user ? next({path: '/user-dashboard'}) : next()
}

function checkRoute(to, from, next) {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        AuthNavigationGuard(to, from, next)
        return
    }
    if (to.matched.some(record => record.meta.guest)) {
        guestNavigationGuard(to, from, next)
        return;
    }
    next()

}

router.beforeEach(async (to, from, next) => {
    let indx = store.getters["menu/indx"]?.length || store.getters["menu/indx"]
    let translate = store.getters["menu/translations"]?.length || store.getters["menu/translations"]

    if (!indx) await store.dispatch('menu/fetchIndx');
    if (!translate) store.dispatch('menu/getTranslations');
    if (!store.getters["user/user"]) {
        return store.dispatch('user/getUser').then(() => {
            checkRoute(to, from, next)
        });
    }

    checkRoute(to, from, next)
})


// smooth scroll config and get main data
const stopHomeScroll = (to) => {
    let scroll = store.getters["scroll/scroller"];

    to?.name === 'home' || to?.name === 'UserDashboard'
    || to?.name === 'map'
    || to?.name === 'calculator'
    || to?.name === 'contactUs'
    || to?.name === 'signUp' ? scroll?.stop() : scroll?.start()
}
const setMobileBodyOverflow = (to) => {
    store.dispatch('scroll/toggleMobileScroll', to?.name === 'home')
}
const setScroll = (to) => {
    setTimeout(() => {
        store.dispatch('scroll/setScroll')
        stopHomeScroll(to)
    }, 2000)
}
const updateScroll = (scroll) => {
    scroll.scrollTo(-1800, {
        duration: .1, disableLerp: true
    })
    scroll?.update()
}
const scrollBehaviorControl = (to) => {
    if (window.innerWidth < 1024) {
        setMobileBodyOverflow(to)
        document.querySelector('html').scrollTop = 0
        return;
    }
    // return if mobile

    //init scroll for first load
    let scroll = store.getters["scroll/scroller"];
    if (!scroll) {
        setScroll(to)
        return
    }

    //update scroll every route change
    setTimeout(() => {
        updateScroll(scroll)
        let myDiv = document.getElementById('tag-match-inner');
        myDiv.scrollTop = 0;
        stopHomeScroll(to)
    }, 1000)
}

const getMainData = async (to) => {
    store.dispatch('menu/getCurrentContent', to).then(() => {
        scrollBehaviorControl(to)
    });
}

router.afterEach((to, from) => {
    getMainData(to)
})

export default router
