<template>
    <Transition name="fade">
        <div v-if="component"
             :class="{ios:iOS}"
             class="pop-up-component">
            <div :style="{background:color}" class="pop-up-content">
                <div class="pop-up-header">
                    <img @click="closePopUp"
                         class="close-icon"
                         src="../../assets/images/close.png" alt="close">
                    <div v-if="showButtons" class="button-container">
                        <log-in-button/>
                        <animated-button
                            class="sigh-up button"
                            @click.native="$router.push({path:'/sign-up'})"
                            :title="fn.tr('Sign up')"/>
                    </div>
                </div>
                <div class="slot-inner">
                    <component :data="data" :is="activeComponent"></component>
                </div>
            </div>
        </div>
    </Transition>
</template>

<script>
import { mapGetters } from "vuex";
import LogInButton from '@/components/forms/LogInButton'
import AnimatedButton from '@/components/custom/AnimatedButton'

export default {
    data() {
        return {
            component: null,
            color: 'white',
            data: ''
        }
    },
    components: {
        LogInButton,
        AnimatedButton
    },
    computed: {
        ...mapGetters({
            user: 'user/user'
        }),
        activeComponent() {
            return this.component ? () => import(`./${ this.component }`) : ''
        },
        showButtons() {
            return this.user ? false : this.component !== 'order/OrderPopUp'
        },
        iOS() {
            return [
                    'iPad Simulator',
                    'iPhone Simulator',
                    'iPod Simulator',
                    'iPad',
                    'iPhone',
                    'iPod'
                ].includes(navigator.platform)
                // iPad on iOS 13 detection
                || ( navigator.userAgent.includes("Mac") && "ontouchend" in document )
        }
    },
    methods: {

        closePopUp() {
            this.component = null
            this.$root.$emit('close_pop_up')
        },
        showPopUp() {
            this.$root.$emit('fire_pop_up', { id: 'order/LogInPopUp' })
        }
    },
    watch: {
        $route(to, from) {
            this.closePopUp();
        }
    },
    created() {
        this.$root.$on('fire_pop_up', ({ id, color, data }) => {
            this.component = id
            this.color = color
            this.data = data
        })
    }
}
</script>

<style lang="scss">
.pop-up-component {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 103;
    background: rgba(13, 19, 33, .5);
    display: flex;
    justify-content: flex-end;

    &.ios {
        .slot-inner {
            padding-bottom: 180px !important;
        }
    }

    .pop-up-content {
        background: white;
        height: 100%;
        padding: 70px 60px 70px 50px;
        width: 791px;
        @media only screen and (max-width: 1650px) and (min-width: 1024px) {
            padding: 35px;
        }
        @media only screen and (max-width: 1023px) and (min-width: 768px) {
            padding: 25px;
            width: 80%;
        }
        @media only screen and (max-width: 767px) {
            padding: 20px;
            width: 100%;
        }

        .pop-up-header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            img {
                cursor: pointer;
            }

            .button-container {
                display: flex;
                align-items: center;

                .button {
                    padding: 10px 30px;
                    border-radius: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 700;
                    font-size: 16px;
                    text-transform: uppercase;
                    color: $black;
                    background: $yellow;
                    text-decoration: none;

                    &.sigh-up {
                        border: 2px solid $blue;
                        color: $blue;
                        background: white;
                        margin-left: 10px;
                    }
                }
            }
        }

        .slot-inner {
            margin-top: 48px;
            max-height: 100%;
            overflow-y: overlay;
            padding-bottom: 150px;
            padding-right: 10px;
            //&::-webkit-scrollbar {
            //    width: 8px;
            //}
            //
            ///* Track */
            //&::-webkit-scrollbar-track {
            //    background: #E6E7E8;
            //    width: 3px;
            //}
            //
            ///* Handle */
            //&::-webkit-scrollbar-thumb {
            //    background: white;
            //    width: 8px;
            //}

            &::-webkit-scrollbar {
                width: 7px; //Needed scrollbar thumb width to be 7px, so had to define whole scrollbar to be 7px wide.
            }

            &::-webkit-scrollbar-thumb {
                background: white;
            }

            &::-webkit-scrollbar-track {
                background: #E6E7E8;
                border-left: 3px solid transparent;
                border-right: 3px solid transparent;
                background-clip: padding-box;
            }
        }
    }

}

$ease: cubic-bezier(.29, 0, .1, .99);

.fade-enter-active,
.fade-leave-active {
    transition: all 1.6s $ease;

    .pop-up-content {
        transform: translateX(0%);
        opacity: 1;
        transition: all .8s $ease;

        .close-icon {
            opacity: 1;
            transform: translateY(0);
            transition: all .8s .6s $ease;
        }

        .button-container {
            opacity: 1;
            transform: translateY(0);
            transition: all .8s .7s $ease;
        }

        .slot-inner {
            transform: translateY(0);
            opacity: 1;
            transition: all .8s .8s $ease;
        }
    }
}

.fade-leave-active {
    transition: all 1s $ease;
}

.fade-enter,
.fade-leave-to {
    .pop-up-content {
        transform: translateX(100%);
        opacity: 0;
        transition: all .8s $ease;

        .slot-inner {
            transform: translateY(50px);
            opacity: 0;
        }

        .close-icon, .button-container {
            opacity: 0;
            transform: translateY(50px);
        }
    }
}
</style>
