<template>
    <div
        :class="{active:activeStepId===2}"
        class="map">
        <h2 class="header-title">{{ mapTitle.one }}<span>{{ mapTitle.two }}</span><br/><span>{{ mapTitle.three }}</span>
        </h2>
        <div class="legend">
            <div class="item">
                <div class="color"></div>
                <span class="title">Not Available</span>
            </div>
            <div class="item">
                <div class="color"></div>
                <span class="title">Available</span>
            </div>
        </div>
        <form-select :options="states"
                     id="counties"
                     :value="null"
                     :showStatePlaceholder="true"
                     class="state-select"
                     placeholder="Choose Texts"
                     @input="setViewBoxEvent"
                     label="Choose Available County"/>
        <div class="map-pop-up-inner">
            <map-svg-component class="map-image"/>
            <div v-if="activeCounty"
                 :style="{transform:popUpTransform}"
                 :class="{available:popUpStatus}"
                 class="available-pop-up">
                <p class="title">{{ activeCounty.title }}<span>({{ activeCounty.state }})</span></p>
                <span v-if="popUpStatus" class="status-title">Available</span>
                <span v-else class="status-title not">Not Available</span>
                <div class="arrow-down"></div>
            </div>
        </div>
    </div>
</template>

<script>
import MapSvgComponent from './dashboard/MapSvgComponent';
import FormSelect from '@/components/forms/FormSelect';
import { mapGetters, mapActions } from "vuex";

export default {
    props: {
        activeStepId: {
            type: Number,
            default: 0
        },
        dataContent: {
            type: Object,
        }
    },
    data() {
        return {
            originalViewBox: '0 0 1460.156 915.307'
        };
    },
    components: {
        MapSvgComponent,
        FormSelect
    },
    computed: {
        ...mapGetters({
            indx: 'menu/indx',
            activeCounty: 'map/activeCounty',
            coordinates: 'map/coordinates',
            usedCounties: 'campaigns/usedCounties'
        }),
        states() {
            let allStates = this.indx?.terms?.usamap?.filter(item => item.pid === 0)?.map(item => ({
                ...item,
                text: item.title,
                value: item.slug
            }));

            allStates?.unshift({
                text: 'All States',
                value: 1,
            });

            return allStates?.map(item => ({
                ...item,
                counties: this.counties?.filter(count => count.pid === item.id)
            }))?.sort((a, b) => a.title.localeCompare(b.title));
        },
        counties() {
            return this.indx?.terms?.usamap?.filter(item => item.pid !== 0);
        },
        popUpTransform() {
            return `translate(${ this.coordinates?.x }px,${ this.coordinates?.y }px)`;
        },
        popUpStatus() {
            let activeState = this.states?.find(state => state.id === this.activeCounty?.pid)?.title;

            return !this.checkIfCountyIsUsed(activeState, this.activeCounty?.title);
        },
        mapTitle() {
            let title = this.indx?.widgets?.['map-title'];
            return {
                one: title?.['title-1'],
                two: title?.['title-2'],
                three: title?.['title-3'],
            };
        }
    },
    methods: {
        ...mapActions({
            setActiveCounty: 'map/setActiveCounty',
            setCoordinate: 'map/setCoordinate',
            setViewBox: 'map/setViewBox',
            getUsedCounties: 'campaigns/getUsedCounties'
        }),
        checkIfCountyIsUsed(state, county) {
            return this.usedCounties?.[state]?.includes(county);
        },
        hover() {
            let items = document.querySelectorAll('#map path');
            items.forEach(item => {
                let countyId = item.id?.split('__')?.[0];
                let stateId = item.id?.split('__')?.[1];

                let state = this.states?.find(state => {
                    return state.slug?.toLowerCase()
                        === stateId?.toLowerCase();
                });
                let county = state?.counties?.find(count => {
                    let itemSug = count.slug?.includes('county') ? count.slug?.toLowerCase() : `${ count.slug?.toLowerCase() }_county`;

                    return itemSug === countyId?.toLowerCase() + '_county';
                });

                if (this.checkIfCountyIsUsed(state?.title, county?.title)) item?.classList?.add("inactive");

                item.addEventListener('mouseover', (event) => {
///nedd see
                    state?.counties?.find(count => {
                        return count.slug?.toLowerCase()
                            === countyId?.toLowerCase() + '_county';
                    });


                    item?.classList?.add("active");

                    this.setCoordinate({
                        x: event.clientX - 98,
                        y: event.offsetY - 81,
                    });

                    this.setActiveCounty({
                        ...county,
                        state: state?.value
                    });
                });
                item.addEventListener('mouseleave', (event) => {
                    item?.classList?.remove("active");
                    this.setActiveCounty(null);
                });
                item.addEventListener('click', () => {
                    if (this.checkIfCountyIsUsed(state?.title, county?.title)) return;
                    this.setActiveCounty(null);
                    this.$root.$emit('fire_pop_up', { id: 'order/OrderPopUp', data: county },);
                });
            });
        },
        setViewBoxEvent(e) {
            let items = document.querySelectorAll('#map path');

            if (e === 1) {
                items.forEach(item => {
                    item?.classList?.remove("transparent");
                });
                this.setViewBox(this.originalViewBox);

                return;
            }
            let state = this.states.find(item => item.slug === e);

            this.setViewBox(state.viewbox);
            items.forEach(item => {
                let stateId = item.id?.split('__')?.[1]?.toLowerCase();
                item?.classList?.remove("transparent");

                if (state?.slug !== stateId) item?.classList?.add("transparent");
            });
        }
    },
    mounted() {
        this.getUsedCounties();
        this.$nextTick(() => {
            setTimeout(() => {
                this.hover();
            }, 500);
        });
    }
};
</script>

<style lang="scss" scoped>
$ease: cubic-bezier(.09, 0, .27, 1);

::v-deep.map {
    position: absolute;
    height: calc(100vh - 92px);
    top: 92px;
    width: 100%;
    pointer-events: none;
    @media only screen and (max-width: 1023px) {
        height: calc(100vh - 70px);
        top: 70px;
    }

    &.active {
        pointer-events: auto;
        z-index: 3;

        .map-image {
            transform: scale(1);
            opacity: 1;
            transition: all 1s .6s $ease;
        }

        .form-group {
            opacity: 1;
            transform: translateY(0);
            pointer-events: auto;
            transition: all 1s .8s $ease !important;
        }

        .header-title {
            opacity: 1;
            transform: translate(-50%, 0);
            transition: all .6s .75s ease;
        }

        .legend {
            opacity: 1;
            transition: all .6s .75s ease;
        }
    }

    .available-pop-up {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 999;
        width: 196px;
        height: 58px;
        background: #0D1321;
        border: 2px solid $yellow;
        border-radius: 21px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        @media only screen and (max-width: 1023px) {
            display: none;
        }

        &.available {
            border: 2px solid $green;
        }

        .arrow-down {
            position: absolute;
            left: 50%;
            transform: translate(-50%, 12px);
            bottom: 0;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid #0D1321;
        }

        .title {
            font-weight: 500;
            font-size: 13px;
            text-align: center;
            color: #FFFFFF;
            display: flex;
            margin-bottom: 0;

            span {
                text-transform: uppercase;
            }
        }

        .status-title {
            font-weight: 700;
            font-size: 21px;
            line-height: 28px;
            text-align: center;
            color: $green;
            display: block;
            display: block;

            &.not {
                color: $yellow;
            }
        }
    }

    .map-pop-up-inner {
        position: relative;
        height: 100%;

    }

    .header-title {
        font-style: normal;
        font-weight: 900;
        font-size: 55px;
        color: #FFFFFF;
        text-align: center;
        text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        opacity: 0;
        transition: all .6s $ease;
        position: absolute;
        top: 10px;
        display: flex;
        align-items: center;
        left: 50%;
        line-height: 67px;
        transform: translate(-50%, 30px);
        z-index: 1;
        margin-bottom: 0;
        @media only screen and (max-width: 1650px) and (min-width: 1024px) {
            font-size: 34px;
            line-height: 55px;
        }
        @media only screen and (max-width: 1023px) {
            position: relative;
            font-size: 34px;
            line-height: unset;
            top: 0;
            margin-top: 30px;
            justify-content: center;
        }
        @media only screen and (max-width: 768px) {
            display: block;
        }

        span {
            margin-left: 10px;

            &:first-child {
                color: $yellow;
                display: inline-block;
            }

            &:last-child {
                color: $green;
            }
        }

    }

    .legend {
        opacity: 0;
        pointer-events: none;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 70px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        transition: all .6s $ease;
        @media only screen and (max-width: 767px) {
            position: relative;
            left: unset;
            top: unset;
            transform: unset;
        }
        .item {
            display: flex;
            align-items: center;

            .color {
                width: 10px;
                height: 10px;
                background: #F3AA00 !important
            }

            .title {
                color: white;
                margin-left: 10px;
            }

            &:last-child {
                margin-left: 15px;

                .color {
                    background: rgb(37, 44, 59) !important
                }

            }
        }
    }

    .map-image {
        height: calc(100% - 80px);
        margin-top: 50px;
        transform: scale(.85);
        opacity: 0;
        transition: all .6s $ease;
        @media only screen and (max-width: 1023px) {
            margin-top: 34px;
        }
    }

    .form-group {
        display: none;
        opacity: 0;
        transform: translateY(30px);
        transition: all .6s $ease;
        pointer-events: none;
        width: 347px;
        margin: 30px auto;
        @media only screen and (max-width: 1023px) {
            display: block;
        }
        @media only screen and (max-width: 500px) {
            width: calc(100% - 42px);
        }

        .d-block {
            display: none !important;
        }
    }

    .state-select {

        svg {
            width: 20px !important;

            path {
                fill: #939699;
            }
        }
    }

}
</style>
