<template>
    <b-form-group
        :id="`fieldset${id}`"
        :label="label"
        :label-for="id"
        valid-feedback="Thank you!"
    >
        <b-form-select v-model="selected"
                       class="stopper-slider"
                       @change="emit"
                       :placeholder="placeholder"
                       :value-field="valueField"
                       :options="options">
            <template v-if="showStatePlaceholder" #first>
                <b-form-select-option :value="null" disabled> {{ fn.tr('Select State') }}</b-form-select-option>
            </template>

        </b-form-select>
        <b-icon icon="chevron-down"></b-icon>
    </b-form-group>
</template>


<script>
export default {
    props: {
        options: {
            type: Array,
            default: () => []
        },
        label: {
            type: String
        },
        id: {
            type: String
        },
        value: {},
        valueField: {
            type: String,
            default: 'value'
        },
        placeholder: {
            type: String,
            default: ''
        },
        showStatePlaceholder: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            selected: 1
        }
    },
    methods: {
        emit() {
            this.$emit('input', this.selected)
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.selected = this.value
        })
    }
}
</script>

<style lang="scss" scoped>
::v-deep.form-group {
    position: relative;
    color: $black !important;

    div {
        position: relative;
    }
}

.bi-chevron-down {
    pointer-events: none;
}

.custom-select {
    border: 1px solid #D9DADB;
    border-radius: 5px;
    width: 100%;
    height: 38px;
    padding-left: 15px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;
    cursor: pointer;
    background: white;
}

svg {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}
</style>
