<template>
  <div class="pin">
    <svg width="26" height="30" viewBox="0 0 26 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
          d="M3.62137 21.4406C3.71289 21.5321 3.80923 21.6188 3.90315 21.7079C6.15983 23.8827 10.6732 27.423 12.7275 29.4774C14.7988 27.4062 19.3723 23.8249 21.6097 21.6549C21.682 21.5827 21.7615 21.5128 21.8337 21.4406C26.7155 16.5587 26.4482 8.91207 21.5664 4.03024C16.6845 -0.851589 8.77053 -0.851589 3.8887 4.03024C-0.993126 8.91207 -1.26046 16.5587 3.62137 21.4406ZM17.0145 8.04745C19.382 10.4149 19.3795 14.2515 17.0145 16.6165C14.6494 18.9816 10.808 18.984 8.44299 16.619C6.07794 14.2539 6.07794 10.4149 8.4454 8.04746C10.8129 5.68 14.6494 5.6824 17.0145 8.04745Z"
          :fill="color"/>
    </svg>
  </div>
</template>


<script>
export default {
  props: {
    color: {
      type: String,
    }
  }
}
</script>

<style lang="scss" scoped>
.pin {
  position: absolute;

  &:nth-of-type(1) {
    top: 85%;
    left: 20%;
  }

  &:nth-of-type(2) {
    top: 65%;
    left: 10%;
  }

  &:nth-of-type(3) {
    top: 50%;
    left: 5%;
  }

  &:nth-of-type(4) {
    top: 30%;
    left: 18%;
  }

  &:nth-of-type(5) {
    top: 20%;
    left: 65%;
  }

  &:nth-of-type(6) {
    top: 35%;
    right: 20%;
  }

  &:nth-of-type(7) {
    top: 60%;
    right: 5%;
  }

  &:nth-of-type(8) {
    top: 80%;
    right: 15%;
  }

  &:nth-of-type(9) {
    top: 55%;
    right: 40%;
  }


}
</style>