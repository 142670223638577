export default {
    namespaced: true, state: () => ({
        _stacked: 'md'
    }), actions: {
        setStacked({commit}, value) {
            commit('SET_STACKED', value)
        },
    }, mutations: {
        SET_STACKED(state, value) {
            state._stacked = value
        },
    }, getters: {
        stacked(state) {
            return state._stacked
        }
    },
}
