<template>
    <div ref="container" class="home-view">
        <div class="frozen-content">
            <home-component
                :content="homeComponentData"
                :active-step-id="activeStepId"/>
            <map-component
                :dataContent="homeComponentData.map"
                :active-step-id="activeStepId"/>
            <slider-home @slide-change="slideChange"
                         :dataContent="homeComponentData.slider"
                         :active-step-id="activeStepId"/>
            <choose-plane @change="slideChange" :active-step-id="activeStepId"/>
        </div>
        <faq :faqContent="faq"/>
        <reviews :content="reviewData"/>
        <home-contact-view/>
        <canvas :class="{active:activeStepId===0,bottom:showBallAnimationOnBottom}" id="canvas"></canvas>
    </div>
</template>

<script>
import {init, destroy} from '../utils/ballAnimation'
import MapComponent from '../components/MapComponent'
import ChoosePlane from "@/components/ChoosePlane";
import Faq from "@/components/Faq";
import Reviews from '@/components/Reviews'
import HomeContactView from "@/components/HomeContactView";
import SliderHome from "@/components/SliderHome";
import HomeComponent from "@/components/HomeComponent";
import restrictNumber from "@/utils/helpers";
import {mapActions, mapGetters, mapState} from "vuex";

export default {
    name: 'HomeView',
    data() {
        return {
            // activeStepId: 0,
            animating: false,
            slideRestriction: false,
            mobileTouchStartY: 0,
            containerHeight: 0,
            heightTimeOut: null,
        }
    },
    components: {
        HomeComponent,
        MapComponent,
        ChoosePlane,
        SliderHome,
        Faq,
        Reviews,
        HomeContactView,
    },
    computed: {
        ...mapGetters({
            scroller: 'scroll/scroller',
            scrollTop: 'scroll/scrollTop',
            current_content: 'menu/current_content',
            homeStepId: 'scroll/getHomeStepId'
        }),
        homeComponentData() {
            let promo = this.current_content ? Object.keys(this.current_content)?.find(item => item.includes('promos')) : ''
            let map = this.current_content ? Object.keys(this.current_content)?.find(item => item.includes('map')) : ''
            let sliderOne = this.current_content ? Object.keys(this.current_content)?.find(item => item.includes('slider_box_one')) : ''
            let sliderTwo = this.current_content ? Object.keys(this.current_content)?.find(item => item.includes('slider_box_two')) : ''
            let sliderThree = this.current_content ? Object.keys(this.current_content)?.find(item => item.includes('slider_box_three')) : ''

            return {
                home: this.current_content?.[promo],
                map: this.current_content?.[map] || {},
                slider: [
                    this.current_content?.[sliderOne],
                    this.current_content?.[sliderTwo],
                    this.current_content?.[sliderThree]
                ],
            }
        },
        reviewData() {
            let review = this.current_content ? Object.keys(this.current_content)?.find(item => item.includes('review')) : ''

            return this.current_content?.[review]
        },
        faq() {
            let faq = this.current_content ? Object.keys(this.current_content)?.find(item => item.includes('faq')) : ''

            return this.current_content?.[faq]
        },
        showBallAnimationOnBottom() {
            return this.scrollTop > this.containerHeight - window.innerHeight - 70
        },
        activeStepId: {
            get() {
                return this.homeStepId
            },
            set(val) {
                this.setHomeStepId(val)
            }

        }
    },
    methods: {
        ...mapActions({
            toggleMobileScroll: 'scroll/toggleMobileScroll',
            setHomeStepId: 'scroll/setHomeStepId'
        }),
        slideChange(value) {
            this.slideRestriction = value
        },
        homeNavigation(e) {
            if (this.animating || (this.activeStepId === 3 && this.slideRestriction)) return;
            if (this.activeStepId === 4 && e.deltaY < 0 && this.scrollTop === 0) {
                this.scroller?.stop()
                if (window.innerWidth < 1024) this.toggleMobileScroll(true)
            } else if (this.activeStepId === 4) {
                this.scroller?.start()
                if (window.innerWidth < 1024) this.toggleMobileScroll(false)
                return;
            }

            this.animating = true
            e.deltaY < 0 ? this.activeStepId = restrictNumber(this.activeStepId - 1, 0, 5)
                : this.activeStepId = restrictNumber(this.activeStepId + 1, 0, 5)

            setTimeout(() => {
                this.animating = false
            }, 1500)
        },
        touchStart(e) {
            this.mobileTouchStartY = e.targetTouches?.[0]?.clientY
        },
        touchEnd(e) {
            if (e.target?.classList?.value?.includes('stopper-slider')
                || e.target?.parentNode?.id === 'map'
                || e.path?.[1]?.id === 'map'
                || (this.activeStepId === 4 && this.slideRestriction)
            ) return

            let newY = e.changedTouches?.[0]?.clientY
            let direction = this.mobileTouchStartY - newY


            if (this.animating || (this.activeStepId === 3 && this.slideRestriction)) return;
            if (this.activeStepId === 4 && direction < 0 && window.scrollY === 0) {
                this.toggleMobileScroll(true)
            } else if (this.activeStepId === 4) {
                this.toggleMobileScroll(false)
                return;
            }

            this.animating = true
            direction < 0 ? this.activeStepId = restrictNumber(this.activeStepId - 1, 0, 5)
                : this.activeStepId = restrictNumber(this.activeStepId + 1, 0, 5)

            setTimeout(() => {
                this.animating = false
            }, 1500)
        },
        mouseWheelEvent() {
            let dom = this.$refs.container

            dom.addEventListener('wheel', this.homeNavigation, {passive: true})
            dom.addEventListener('touchstart', this.touchStart, {passive: true})
            dom.addEventListener('touchend', this.touchEnd, {passive: true})
        },
        removeWheelEvent() {
            let dom = this.$refs.container

            dom.removeEventListener('wheel', this.homeNavigation)
            dom.removeEventListener('touchstart', this.touchStart)
            dom.removeEventListener('touchend', this.touchEnd)
        },
    },
    mounted() {
        this.mouseWheelEvent();
        this.$nextTick(() => {
            init();
            this.heightTimeOut = setTimeout(() => {
                this.containerHeight = this.$refs?.container?.getBoundingClientRect()?.height
            }, 300)
        })
    },
    beforeDestroy() {
        if (this.heightTimeOut) clearTimeout(this.heightTimeOut)
        this.removeWheelEvent();
        destroy()

    }
}
</script>

<style lang="scss" scoped>
$ease: cubic-bezier(.09, 0, .27, 1);

.home-view {
    width: 100vw;
    overflow: hidden;
    left: 0;
    background: $black;

    //height: calc(100% - 122px) !important;
    //min-height: unset !important;

    .frozen-content {
        position: relative;
        height: 100vh;
        overflow: hidden;
    }

    #canvas {
        position: fixed;
        left: -100px;
        top: -100px;
        pointer-events: none;
        opacity: 0;
        transition: opacity .8s .2s $ease;

        &.active {
            opacity: 1;
            transition: opacity .8s .8s $ease;
        }

        &.bottom {
            opacity: 1;
            bottom: -100px;
            top: unset;
            transition: opacity .8s .8s $ease;
        }
    }

}
</style>
