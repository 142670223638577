import Vuex from 'vuex'
import Vue from 'vue'
import scroll from './modules/scroll'
import menu from './modules/menu'
import map from './modules/map'
import forms from './modules/forms'
import user from './modules/user'
import campaigns from './modules/campaigns'
import table from './modules/table'
import resize from './modules/resize'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        scroll, menu, map, forms, user, campaigns, table, resize
    },
})
