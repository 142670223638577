import axios from "axios";
import get from "lodash/get";
import conf from "./conf";
import store from '../store/index'

export default {
    async postData(path = "", data = {}) {
        return this.reqAxios(path, data).then((data) => {
            return data;
        });

    },
    async adminPost(path = "", data = {}) {
        return this.reqAxios(path, data, true).then((data) => {
            return data;
        });

    },

    async reqAxios(path = "", data = {}, admin = false) {
        let mainPath = admin ? conf.adminUrl : conf.apiUrl

        const response = await axios(mainPath + path, {
            method: "POST", headers: {
                'authorization': `bearer ${localStorage.getItem('access_token')}`
            }, data: data,

        }).then((response) => {
            return response;
        });

        if (response.status < 300) {
            return {data: response.data, status: response.status};
        }
    },
    /** Get translated strings
     * If a string does not exist, make a request to create one */
    tr(wordkey, params) {
        if (!wordkey || wordkey === "") return wordkey;

        wordkey = wordkey.trim();
        let translations = store.getters["menu/translations"]


        //@ts-ignore
        if (!translations?.['en']) return this.sprintf(wordkey, params);

        if (get(translations, ['en', wordkey])) return this.sprintf(get(translations, ['en', wordkey]), params);
        if (get(translations, ['en', wordkey.toLowerCase()])) return this.sprintf(get(translations, ['en', wordkey.toLowerCase()]), params);

        this.postData("main/adwrd", {wrd: wordkey});
        return this.sprintf(wordkey, params);
    },

    sprintf(string, params) {
        if (!Array.isArray(params)) return string;
        for (let i in params) string = string.replace("%s", params[i]);
        return string;
    },
};
