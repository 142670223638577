<template>
    <div class="faq-inner">
        <div class="pinSection" id="pinSection"></div>
        <div data-scroll
             data-scroll-sticky
             data-scroll-target="#pinSection"
             data-scroll-offset="-20%"
             class="left">
            <div class="header-title" v-html="content.title"></div>
            <div class="description" v-html="content.teaser"></div>
            <animated-button
                @click.native="openPop"
                :title="fn.tr('Fill the form')"/>
        </div>
        <div class="right">
            <div v-for="(item,index) in faq"
                 data-scroll
                 :data-scroll-speed="index/3"
                 :key="index" class="faq-item">
                <h2 class="item-header">{{ item.title }}</h2>
                <div class="line"></div>
                <span class="item-title" v-html="item.content"></span>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import AnimatedButton from '@/components/custom/AnimatedButton'

export default {
    props: {
        faqContent: {
            type: Object
        },
    },
    data() {
        return {}
    },
    components: {
        AnimatedButton
    },
    computed: {
        ...mapGetters({
            indx: 'menu/indx',
        }),
        content() {
            return this.indx?.widgets?.['lets-get-started'] || {}
        },
        faq() {
            return this.faqContent?.data?.list || []
        },
    },
    methods: {
        openPop() {
            this.$root.$emit('fire_pop_up', {id: 'order/OrderPopUp'},)
        }
    }
}
</script>

<style lang="scss" scoped>
.faq-inner {
    display: flex;
    align-items: flex-start;
    margin-left: 15%;
    padding-top: 180px;
    position: relative;
    @media only screen and (max-width: 1023px) {
        margin-left: 0;
        padding: 0 21px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .pinSection {
        position: absolute;
        height: calc(100% - 300px);
        pointer-events: none;
    }

    ::v-deep .left {
        color: $grey;
        @media only screen and (max-width: 1023px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-bottom: 55px;
        }

        ::selection {
            color: $black;
            background: $yellow;
        }

        .header-title {
            font-weight: 900;
            font-size: 55px;
            line-height: 67px;
            color: white;
            @media only screen and (max-width: 1023px) {
                font-size: 21px;
            }

            span {
                color: $yellow;
            }
        }

        .description {
            font-weight: 400;
            font-size: 21px;
            line-height: 25px;
            margin-top: 34px;

            @media only screen and (max-width: 1023px) {
                text-align: left;
                font-size: 18px;
                line-height: unset;
            }

            p {
                margin-bottom: 0;
            }

            a {
                color: $yellow;
                font-style: italic;
                font-weight: 400;
                font-size: 16px;
            }
        }

        .title {
            display: block;
            margin-top: 15px;
            font-style: italic;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;

            a {
                color: $yellow;
            }
        }

        .speech {
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            margin-top: 15px;
            display: block;
        }

        .button {
            width: 179px;
            height: 40px;
            background: $yellow;
            border-radius: 30px;
            margin-top: 20px;
            font-weight: 700;
            font-size: 16px;
            text-align: center;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $black;
            cursor: pointer;
            transition: all .45s ease;

        }
    }

    .right {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-left: 35px;
        @media only screen and (max-width: 1023px) {
            margin-left: 0;
        }

        .faq-item {
            background: #FFFFFF;
            border-radius: 10px;
            padding: 35px 19px;
            display: flex;
            margin-top: 50px;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 276px;
            @media only screen and (max-width: 1023px) {
                width: calc(50% - 25px);
            }
            @media only screen and (max-width: 500px) {
                width: 100%;
                margin-left: 0 !important;
                margin-top: 20px !important;
            }

            &:nth-child(2n) {
                margin: 25px 0 0 25px;
            }

            .item-header {
                font-weight: 700;
                font-size: 21px;
                text-align: center;
                color: $black;
            }

            .line {
                width: 19.24px;
                height: 2px;
                background: $green;
                margin: 30px 0;
            }

            .item-title {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: $black;
                text-align: center;
            }
        }
    }
}
</style>
