import fn from '../../helpers/func'
import router from "@/router";
import moment from "moment";

export default {
    namespaced: true, state: () => ({
        user: null, _leads: null, accessToken: localStorage.getItem("access_token"), _calcs: null
    }), getters: {
        user(state) {
            return state.user
        }, leads(state) {
            return state._leads || []
        }, calcs(state) {
            return state._calcs
        },
    }, actions: {
        async logOut({commit}) {
            return fn.postData("user/logout").then((res) => {
                if (res.status === 200) {
                    commit('SET_USER', null)
                    router.push({path: '/sign-up'})
                }
            })
        }, async getUser({commit}) {
            return fn.adminPost("me").then((res) => {
                if (res.status === 200) commit('SET_USER', res.data)
            })
        }, async getLeads({commit}, {
            date = undefined,
            period = undefined,
        }) {
            let formatDate = date ? date?.map(item => moment(item)?.format('YYYY-MM-DD')) : date

            let {data} = await fn.adminPost("tagAndMatch/getLeads", {
                date: formatDate,
                period,
                limit: 100
            });

            commit('SET_LEADS', data?.list)
            commit('SET_CALCS', data?.calcs)
        },

    }, mutations: {
        SET_USER(state, data) {
            state.user = data
        }, SET_LEADS(state, data) {
            state._leads = data
        }, SET_CALCS(state, data) {
            state._calcs = data
        },
    },
}
