export default {
    namespaced: true, state: () => ({
        _premium: false
    }), actions: {
        setPremium({commit}, value) {
            commit('SET_PREMIUM', value)
        },
    }, mutations: {
        SET_PREMIUM(state, value) {
            state._premium = value
        },
    }, getters: {
        premium(state) {
            return state._premium
        }
    },
}
