<template>
    <button :class="{'stopper-slider':mobile}"
            :disabled="disable"
            class="button">
        <div :class="{'stopper-slider':mobile}" class="span-inner">
            <span :class="{'stopper-slider':mobile}">{{ title }}</span>
            <span :class="{'stopper-slider':mobile}" class=" last">{{ title }}</span>
        </div>
    </button>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        disable: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters({
            size: 'resize/size'
        }),
        mobile() {
            return this.size < 1024
        }
    }
}
</script>

<style lang="scss" scoped>
.button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Inter';
    border: unset;
    text-decoration: none;

    &:disabled {
        background: #0D1321;
        pointer-events: none;
    }

    .last {
        @media only screen and (max-width: 1023px) {
            display: none !important;
        }
    }

    .stopper-slider {
        padding: 0 !important;
        position: relative !important;
        height: auto !important;
        left: unset !important;
        top: unset !important;
        transform: unset !important;
        width: fit-content !important;
    }

    .span-inner {
        position: relative;
        flex-direction: column;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 1;
    }

    &:hover {
        span {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            -webkit-line-clamp: 1;


            &:first-child {
                animation-duration: .6s;
                animation-name: btnAnimFirst;
                animation-fill-mode: forwards;
                will-change: transform;
                @media only screen and (max-width: 1023px) {
                    animation: unset;
                }
            }

            &:last-child {
                animation-duration: .6s;
                animation-name: btnAnimLast;
                animation-fill-mode: forwards;
                will-change: transform;
            }
        }
    }

    span {
        line-height: 18px !important;
        text-decoration: none !important;

        &:first-child {
            position: absolute;
            top: 0;
            left: 0;
            @media only screen and (max-width: 1023px) {
                position: relative;
                top: unset;
                left: unset;
            }
        }

        &:last-child {
            display: -webkit-box;
            transform: translateY(100%);
        }
    }

    @keyframes btnAnimFirst {
        0% {
            transform: translateY(0);
        }
        100% {
            transform: translateY(-100%);
        }
    }
    @keyframes btnAnimLast {
        0% {
            transform: translateY(100%);
        }
        100% {
            transform: translateY(0);
        }
    }
}
</style>
